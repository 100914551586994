import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_BUYER_MODAL,
    ASSIGN_FPO_MODAL,
} from "../../types/constants";
import { useParams, NavLink, useLocation } from "react-router-dom";
import useAssignFPO from "../../hooks/useAssignFPO";
import useIsAdmin from "../../hooks/useIsAdmin";

interface UserObj {
    id: string;
    role: string;
    name: string;
    email: string;
    createdAt: string;
}

interface TableColumn {
    Header: string;
    accessor: string;
    Cell: ({ row }: any) => JSX.Element;
}

interface BuyerProps {
    otherUrl?: string;
    otherFields?: TableColumn[];
}

const UserBuyers = (props: BuyerProps) => {
    const params = useParams();
    const fpo_id = params?.id || null;
    const { otherUrl, otherFields } = props;
    const { handleFPOAssign } = useAssignFPO();
    const { isAdmin } = useIsAdmin();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => {
                return row.index + 1;
            },
        },
        // {
        //     Header: "User ID",
        //     accessor: "id",
        //     Cell: ({ row }: any) => {
        //         const id = row?.original.id;
        //         return (
        //             <NavLink
        //                 to={`${row?.original.id}`}
        //                 className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
        //                 title={`View General User Details`}
        //             >
        //                 {id}
        //             </NavLink>
        //         );
        //     },
        // },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }: any) => {
                const name = row?.original.name || "Not Available";
                const id = row?.original.id || "#";
                return (
                    <NavLink
                        to={`details/${id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title="View General User Details"
                    >
                        {name}
                    </NavLink>
                );
            },
        },
        {
            Header: "Role",
            accessor: "role",
            Cell: ({ value }: { value: string }) => value.charAt(0).toUpperCase() + value.slice(1),
        },
        // {
        //     Header: "Email",
        //     accessor: "email",
        // },
        {
            Header: "Relationship Manager",
            accessor: "relationshipManager",
            Cell: ({ row }: any) => {
                const relationshipManager = row?.original.relationshipManager;
                return relationshipManager === null ? (
                    <p >Not Available</p>
                ) : (
                    <NavLink
                        to={`${row?.original.id}`}
                        className="text-green px-2 py-3"
                    >
                        {relationshipManager}
                    </NavLink>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "createdAt",
            Cell: ({ value }: any) => {
                return value ? new Date(value).toLocaleString() : "Invalid Date";
            },
        },
        // {
        //     Header: "Edit Access",
        //     accessor: "editAccess",
        //     Cell: ({ value }: any) => {
        //         return <>{value ? "Yes" : "No"}</>;
        //     },
        // },
        // {
        //     Header: "Edit",
        //     accessor: "edit",
        //     Cell: ({ row }: any) => {
        //         return (
        //             <button
        //                 onClick={() => {
        //                     dispatch(
        //                         showModal({
        //                             modalType: EDIT_BUYER_MODAL,
        //                             modalTitle: "Edit General User",
        //                             modalProps: {
        //                                 user_id: row.original.id,
        //                                 name: row.original.name,
        //                                 role: row.original.role,
        //                                 email: row.original.email,
        //                                 relationshipManager: row.original.relationshipManager,
        //                             },
        //                         })
        //                     );
        //                 }}
        //                 className="bg-green-600 text-white px-2 py-3"
        //             >
        //                 <FaPen />
        //             </button>
        //         );
        //     },
        // },
    ];
    if (isAdmin) {
        ALL_USERS_COLUMNS.push({
            Header: "Assinged FPO",
            accessor: "fpo_id",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const fpo_name = row.original.fpo_name || null;
                return (
                    <>
                        {fpo_id && (
                            <p className="text-green-400">
                                {fpo_name} :
                                <br />({fpo_id})
                            </p>
                        )}
                        {!fpo_id && (
                            <p className="text-red-400">No FPO assigned</p>
                        )}
                    </>
                );
            },
        });
        ALL_USERS_COLUMNS.push({
            Header: "Assing/Unassign FPO",
            accessor: "updatedAt",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const is_fpo_details_page =
                    useLocation().pathname.includes("/fpo/details");
                return (
                    <>
                        <button
                            onClick={() => {
                                if (fpo_id) {
                                    handleFPOAssign({
                                        id: row.original.id,
                                        action: "unassign",
                                        user_role: "user",
                                        fpo_id: row.original.fpo_id,
                                    });
                                    return;
                                }
                                dispatch(
                                    showModal({
                                        modalType: ASSIGN_FPO_MODAL,
                                        modalTitle: "Assign FPO Modal",
                                        modalProps: {
                                            id: row.original.id,
                                            action: fpo_id
                                                ? "unassign"
                                                : "assign",
                                            role: "user",
                                            fpo_id: fpo_id,
                                        },
                                    })
                                );
                            }}
                            className={`${fpo_id ? "bg-red-500" : "bg-green-600"
                                } text-white px-2 py-3`}
                        >
                            {is_fpo_details_page
                                ? "Unlink General User"
                                : fpo_id
                                    ? "Unassign FPO"
                                    : "Assign FPO"}
                        </button>
                    </>
                );
            },
        });
    }

    if (otherFields) {
        const validFields = otherFields.map(field => ({
            ...field,
            Cell: field.Cell || (() => <></>)
        }));
        ALL_USERS_COLUMNS.push(...validFields);
    }

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState("createdAt");
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getAllUsers = async ({ queryKey }: any) => {
        const { data } = await axiosInstance.get(otherUrl || `/admin/users`, {
            params: {
                fpo_id: fpo_id,
            },
        });
        const filteredData = data.data.filter(
            (e: any) => e.role !== "seller" || e.role !== "admin"
        );
        return filteredData;
    };

    const getAllCertificates = async () => {
        const { data } = await axiosInstance.get(
            `/admin/seller-certification-requests`
        );

    };

    useEffect(() => {
        getAllCertificates();
    }, []);

    const { data, error, isLoading } = useQuery<
        UserObj[] | undefined,
        any,
        UserObj[],
        any[]
    >(["getAllUsers", pageIndex, cPageSize, cSortBy, desc, q], getAllUsers);

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />

                    <span className="btn-primary flex items-center mt-5 w-48">
                        Selected Row : {selectedRows.length}
                    </span>

                </div>

                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_BUYER_MODAL,
                                    modalTitle: "Add a General User",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default UserBuyers;
