import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { LENGTH_UNIT } from "../../types/constants"

interface FormValues {
  kiln_name: string;
  kiln_type: string;
  depth: number;
  upper_diameter: number;
  lower_diameter: number;
  address: string;
}

const AddPyrolysisKilnModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [img, setImg] = useState<Object | null>(null);
  const [depthUnit, setDepthUnit] = useState("cm");
  const [upperDiameterUnit, setUpperDiameterUnit] = useState("cm");
  const [lowerDiameterUnit, setLowerDiameterUnit] = useState("cm");

  const initialValues: FormValues = {
    kiln_name: "",
    kiln_type: "",
    depth: 0,
    upper_diameter: 0,
    lower_diameter: 0,
    address: "",
  };

  const validationSchema = Yup.object({
    kiln_name: Yup.string().trim().required("Kiln Name is required"),
    kiln_type: Yup.string().trim().required("Kiln Type is required"),
    depth: Yup.number()
      .min(0.002, "Depth must be at least 0.002")
      .required("Depth is required"),
    upper_diameter: Yup.number()
      .min(0.002, "Upper Diameter must be at least 0.002")
      .required("Upper Diameter is required"),
    lower_diameter: Yup.number()
      .min(0.002, "Lower Diameter must be at least 0.002")
      .required("Lower Diameter is required"),
    address: Yup.string().trim().required("Address is required"),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleSubmit = async (kiln: FormValues) => {
    let lat = "12.971599";
    let long = "77.594566";
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((pos) => {
        lat = pos.coords.latitude.toString();
        long = pos.coords.longitude.toString();
      });
    }
    if (!img) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Image is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const imgRes = await uploadImage(img, AZURE_STORAGE_DIRECTORY.PYROLYSIS_KILN, AZURE_STORAGE_SUBDIRECTORY.PROFILE);

      if (imgRes.data.publicUrl) {
        const obj = {
          ...kiln,
          kiln_photo: imgRes.data.publicUrl,
          lat: lat,
          lng: long,
        };

        const result = await axiosInstance.post("/pyrolysis_kiln/create", obj);
        if (result) {

          setIsSubmitting(false);
          queryClient.invalidateQueries(["getAllPyrolysisKiln"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Pyrolysis kiln added successfully!",
            })
          );
          dispatch(hideModal());
        }
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;
        switch (response.status) {
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{ minWidth: "40vw" }}
        >
          <div className="flex item-center gap-x-6">
            <Input
              label="Kiln Name"
              id="kiln_name"
              name="kiln_name"
              type="text"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Kiln Type"
              id="kiln_type"
              name="kiln_type"
              type="text"
            />
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Depth (${depthUnit})`}
                id="depth"
                name="depth"
                type="number"
              />
            </div>
            <div>
              <select
                value={depthUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(depthUnit, newUnit);
                  setFieldValue("depth", values.depth * conversionFactor);
                  setDepthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Upper Diameter (${upperDiameterUnit})`}
                id="upper_diameter"
                name="upper_diameter"
                type="number"
              />
            </div>
            <div>
              <select
                value={upperDiameterUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(upperDiameterUnit, newUnit);
                  setFieldValue("upper_diameter", values.upper_diameter * conversionFactor);
                  setUpperDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Lower Diameter (${lowerDiameterUnit})`}
                id="lower_diameter"
                name="lower_diameter"
                type="number"
              />
            </div>
            <div>
              <select
                value={lowerDiameterUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(lowerDiameterUnit, newUnit);
                  setFieldValue("lower_diameter", values.lower_diameter * conversionFactor);
                  setLowerDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Address"
              id="address"
              name="address"
              type="text"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Upload kiln Photo"
              id="kiln_photo"
              name="kiln_photo"
              type="file"
              accept=".jpg,.png,.jpeg,.wbep"
              disabled={!!img}
              onChange={(e) => {
                if (e.target.files) {
                  setImg(e.target.files[0]);
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Add Pyrolysis kiln"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddPyrolysisKilnModal;