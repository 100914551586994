import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_ARTISAN_MODAL,
    ASSIGN_FPO_MODAL,
    checkStrings,
    ASSIGN_PROJECT_MODAL
} from "../../types/constants";
import { NavLink, useLocation } from "react-router-dom";
import useIsAdmin from "../../hooks/useIsAdmin";
import useAssignFPO from "../../hooks/useAssignFPO";


interface UserObj {
    artisan_id: string;
    name: string;
    createdAt: string;
    company_email?: string;
    company_mobile?: string;

}

const AllApprovedArtisan = () => {
    const { isAdmin } = useIsAdmin();
    const { handleFPOAssign } = useAssignFPO();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => {
                return row.index + 1;
            },
        },
        {
            Header: "Id",
            accessor: "artisan_id",
            Cell: ({ row }: any) => {
                const artisan_id = row?.original.artisan_id;
                return (
                    <NavLink
                        to={`${row?.original.artisan_id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title={`View Approved Artisan Details`}
                    >
                        {artisan_id}
                    </NavLink>
                );
            }
        },
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }: any) => {
                const name = row?.original.name || "Not Available";
                const artisan_id = row?.original.artisan_id || "#";
                return (
                    <NavLink
                        to={`${artisan_id}`}
                        className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
                        title="View Approved Artisan Details"
                    >
                        {name}
                    </NavLink>
                );
            }
        },
        // {
        //     Header: "Company Email",
        //     accessor: "company_email",
        // },
        // {
        //     Header: "Company Mobile",
        //     accessor: "company_mobile",
        // },
        // {
        //     Header: "KYC Image",
        //     accessor: "artisan_kyc_doc_url",
        //     Cell: ({ value }: any) => {
        //         return (
        //             <a
        //                 href={value}
        //                 className="text-green-400"
        //                 target="_blank"
        //                 rel="noreferrer"
        //             >
        //                 <button className="bg-green-600 text-white px-2 py-3">
        //                     <FaEye />
        //                 </button>
        //             </a>
        //         );
        //         // return <img src={value} className="h-44" />
        //     },
        // },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) => {
                return value ? new Date(value).toLocaleString() : "Invalid Date";
            },
        },
        // {
        //     Header: "Assign Biomass",
        //     accessor: "address",
        //     Cell: ({ value, row }: any) => {
        //         const biomass_compositions: Option[] = (
        //             (row?.original?.biomass_compositions as any[]) || []
        //         ).map((element) => {
        //             return {
        //                 label: element.biomass_name,
        //                 value: element.biomass_composition_id,
        //             };
        //         });

        //         return (
        //             <div>
        //                 <button
        //                     onClick={() => {
        //                         dispatch(
        //                             showModal({
        //                                 modalType: ASSIGN_BIOMASS_MODAL,
        //                                 modalTitle: "Assign Biomass",
        //                                 modalProps: {
        //                                     artisan_id: row.original.artisan_id,
        //                                     assigned_biomass:
        //                                         biomass_compositions,
        //                                 },
        //                             })
        //                         );
        //                     }}
        //                     className="bg-green-600 text-white px-2 py-3"
        //                 >
        //                     {biomass_compositions.length > 0
        //                         ? <FaPen />
        //                         : "Assign Biomass"}
        //                 </button>
        //             </div>
        //         );
        //     },
        // },
        // {
        //     Header: "Projects list",
        //     accessor: "project_id",
        //     Cell: ({ value, row }: any) => {
        //         return (
        //             <button
        //                 className="bg-green-600 text-white px-2 py-3"
        //                 onClick={() => {
        //                     dispatch(
        //                         showModal({
        //                             modalType: VIEW_ARTISAN_OR_PROJECT_MODAL,
        //                             modalTitle: "View Projects",
        //                             modalProps: {
        //                                 isProjectsList: true,
        //                                 id: row.original.artisan_id,
        //                             },
        //                         })
        //                     );
        //                 }}
        //             >
        //                 <FaEye />
        //             </button>
        //         );
        //     },
        // },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ value, row }: any) => {
                return value === "approved" ? (
                    "Approved"
                ) : (
                    <button
                        // onClick={() => { approveArtisan(row.original.artisan_id) }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        Approve Artisan
                    </button>
                );
            },
        },
    ];
    if (isAdmin) {
        ALL_USERS_COLUMNS.push({
            Header: "Assinged FPO",
            accessor: "fpo_id",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const fpo_name = row.original.fpo_name || null;
                return (
                    <>
                        {fpo_id && (
                            <p className="text-green-400">
                                {fpo_name} :
                                <br />({fpo_id})
                            </p>
                        )}
                        {!fpo_id && (
                            <p className="text-red-400">No FPO assigned</p>
                        )}
                    </>
                );
            },
        });
        ALL_USERS_COLUMNS.push({
            Header: "Assing/Unassign FPO",
            accessor: "updatedAt",
            Cell: ({ row }: any) => {
                const fpo_id = row.original.fpo_id || null;
                const is_fpo_details_page =
                    useLocation().pathname.includes("/fpo/details");
                return (
                    <>
                        <button
                            onClick={() => {
                                if (fpo_id) {
                                    handleFPOAssign({
                                        id: row.original.user_id,
                                        action: "unassign",
                                        user_role: "artisan",
                                        fpo_id: row.original.fpo_id,
                                    });
                                    return;
                                }
                                dispatch(
                                    showModal({
                                        modalType: ASSIGN_FPO_MODAL,
                                        modalTitle: "Assign FPO Modal",
                                        modalProps: {
                                            id: row.original.user_id,
                                            action: fpo_id
                                                ? "unassign"
                                                : "assign",
                                            role: "artisan",
                                            fpo_id: fpo_id,
                                        },
                                    })
                                );
                            }}
                            className={`${fpo_id ? "bg-red-500" : "bg-green-600"
                                } text-white px-2 py-3`}
                        >
                            {is_fpo_details_page
                                ? "Unlink Artisan"
                                : fpo_id
                                    ? "Unassign FPO"
                                    : "Assign FPO"}
                        </button>
                    </>
                );
            },
        });
        ALL_USERS_COLUMNS.push({
            Header: "Assign Project",
            accessor: "assign_project",
            Cell: ({ row }: any) => {
                return (
                    <>
                        <button
                            onClick={() => {
                                dispatch(
                                    showModal({
                                        modalType: ASSIGN_PROJECT_MODAL,
                                        modalTitle: "Assign Project to Artisan",
                                        modalProps: {
                                            artisan_id: row.original.artisan_id,
                                            action: "assign",

                                        },
                                    })
                                );
                            }}
                            className={`bg-green-600 text-white px-2 py-3`}
                        >
                            Assign Project
                        </button>
                    </>
                );
            },
        });
    }

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState("created_at");
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getArtisans = async (queryKey: string) => {
        const { data } = await axiosInstance.get(
            `/artisan/all?status=approved`
        );

        const response: UserObj[] = [];
        data?.data?.forEach((item: UserObj) => {
            if (checkStrings(item.name, queryKey) || checkStrings(item.company_email as string, queryKey) || checkStrings(item.company_mobile as string, queryKey) || checkStrings(item.artisan_id, queryKey)) {
                response.push(item);
            }
        })
        console.log(response);
        return response;
    };

    const { data, error, isLoading } = useQuery<
        UserObj[] | undefined,
        any,
        UserObj[],
        any[]
    >(["getArtisans", pageIndex, cPageSize, cSortBy, desc, q], () => getArtisans(q));

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }


    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />

                    <span className="btn-primary flex items-center mt-5 w-48">
                        Selected Row : {selectedRows.length}
                    </span>

                </div>
                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_ARTISAN_MODAL,
                                    modalTitle: "Add a Artisan",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default AllApprovedArtisan;
