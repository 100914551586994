import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    VIEW_BIOCHAR_MODAL,
    VIEW_PREPARATION_MODAL,
    VIEW_PYROLYSIS_MODAL,
    VIEW_SEQUESTRATION_MODAL,
} from "../../types/constants";
import { FaEye, FaTrash } from "react-icons/fa";
import { useParams, NavLink } from "react-router-dom";
import { addToast } from "../../redux/features/toastSlice";
import {
    ERROR,
    SUCCESS
} from "../../types/constants";
import { AxiosError } from "axios";


interface AllBatchDetailsObj {
    preparation_id: any;
    pyrolysis_id: any;
    biochar_id: any;
    status: string;
    biomass_batch_id: string;
    name: string;
    companyName: string;
    createdAt: string;
}

const AllBatchDetails = () => {

    const getPreparationDetails = async (value: string) => {
        try {
            const res = await axiosInstance.get(
                "/biomass_batch/preparation/" + value
            );

            dispatch(
                showModal({
                    modalType: VIEW_PREPARATION_MODAL,
                    modalTitle: "View Preparation Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const getPyrolysisDetails = async (value: string) => {
        try {
            const res = await axiosInstance.get("/biomass_batch/pyrolysis/" + value);
            dispatch(
                showModal({
                    modalType: VIEW_PYROLYSIS_MODAL,
                    modalTitle: "View Pyrolysis Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const getBioCharDetails = async (value: string) => {
        try {
            const res = await axiosInstance.get("/biomass_batch/biochar/" + value);
            dispatch(
                showModal({
                    modalType: VIEW_BIOCHAR_MODAL,
                    modalTitle: "View BioChar Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const getSequestrationDetails = async (batchId: string, value: string) => {
        try {
            const res = await axiosInstance.get(
                `/biomass_batch/sequestration/${batchId}/${value}`
            );

            dispatch(
                showModal({
                    modalType: VIEW_SEQUESTRATION_MODAL,
                    modalTitle: "View Sequestration Details",
                    modalProps: {
                        data: res.data.data,
                    },
                })
            );
        } catch (error: any) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };

    const { id } = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Batch ID",
            accessor: "biomass_batch_id",
        },
        {
            Header: "Created At",
            accessor: "createdAt",
            Cell: ({ value }: any) => {
                return new Date(value).toLocaleString();
            },
        },
        {
            Header: "Updated At",
            accessor: "updatedAt",
            Cell: ({ value }: any) => {
                return new Date(value).toLocaleString();
            },
        },
        {
            Header: "View Preparation",
            accessor: "biomass_preparation",
            Cell: ({ value }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    dispatch(
                                        showModal({
                                            modalType: VIEW_PREPARATION_MODAL,
                                            modalTitle: "View Preparation Details",
                                            modalProps: {
                                                data: value,
                                            },
                                        })
                                    );
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                <button className="bg-green-600 text-white px-2 py-3">
                                    <FaEye />
                                </button>
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },

        {
            Header: "View Pyrolysis Detail",
            accessor: "pyrolysis_detail",
            Cell: ({ value }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    dispatch(
                                        showModal({
                                            modalType: VIEW_PYROLYSIS_MODAL,
                                            modalTitle: "View Pyrolysis Details",
                                            modalProps: {
                                                data: value,
                                            },
                                        })
                                    );
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                <button className="bg-green-600 text-white px-2 py-3">
                                    <FaEye />
                                </button>
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },

        {
            Header: "View BioChar Details",
            accessor: "biochar_detail",
            Cell: ({ value }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    dispatch(
                                        showModal({
                                            modalType: VIEW_BIOCHAR_MODAL,
                                            modalTitle: "View BioChar Details",
                                            modalProps: {
                                                data: value,
                                            },
                                        })
                                    );
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                <button className="bg-green-600 text-white px-2 py-3">
                                    <FaEye />
                                </button>
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },

        {
            Header: "View Sequestration Details",
            accessor: "sequestration",
            Cell: ({ value, row }: any) => {
                return (
                    <div>
                        {value ? (
                            <p
                                onClick={() => {
                                    dispatch(
                                        showModal({
                                            modalType: VIEW_SEQUESTRATION_MODAL,
                                            modalTitle: "View Sequestration Details",
                                            modalProps: {
                                                data: value,
                                            },
                                        })
                                    );
                                }}
                                className="text-green-400 cursor-pointer"
                            >
                                <button className="bg-green-600 text-white px-2 py-3">
                                    <FaEye />
                                </button>
                            </p>
                        ) : (
                            <p className="text-red-400">Not Available</p>
                        )}
                    </div>
                );
            },
        },
        // {
        //     Header: "Status",
        //     accessor: "status",
        // },
        // {
        //     Header: "Status",
        //     accessor: "status",
        //     Cell: ({ value }: any) => {
        //         const statusColors: Record<string, string> = {
        //             draft: "bg-pink-500 text-white ",
        //             submitted: "bg-blue-500 text-white",
        //             deleted: "bg-red-500 text-white",
        //             completed: "bg-green-500 text-white",
        //             sequestered: "bg-yellow-700 text-white",
        //             cancelled: "bg-red-500 text-white",
        //             accepted: "bg-green-500 text-white",
        //         };
        //         const displayValue = value ? value.charAt(0).toUpperCase() + value.slice(1) : "Not Available";
        //         return (
        //             <span
        //                 className={`px-2 py-3 font-bold ${statusColors[value] || "bg-gray-300 text-black"}`}
        //             >
        //                 {displayValue}
        //             </span>
        //         );
        //     },
        // },


        // {
        //     Header: "Delete Biochar",
        //     accessor: "deleteBiochar",
        //     Cell: ({ value, row }: any) => {
        //         return (
        //             <button
        //                 onClick={() => {
        //                     const biomass_batch_id = row?.original?.biomass_batch_id;
        //                     if (!biomass_batch_id) {
        //                         dispatch(
        //                             addToast({
        //                                 kind: ERROR,
        //                                 msg: "Error: Biochar ID is missing.",
        //                             })
        //                         );
        //                         return;
        //                     }
        //                     axiosInstance.delete(`/biomass_batch/${biomass_batch_id}`)
        //                         .then((res) => {

        //                             dispatch(
        //                                 addToast({
        //                                     kind: SUCCESS,
        //                                     msg: "BioChar Deleted Successfully",
        //                                 })
        //                             );
        //                             queryClient.invalidateQueries("getSingleBiomassBatch")
        //                         }).catch((error: AxiosError) => {
        //                             console.log(error.response);
        //                             dispatch(
        //                                 addToast({
        //                                     kind: ERROR,
        //                                     msg: `Error: ${error.response?.data?.msg || "Failed to delete material"}`,
        //                                 })
        //                             );
        //                             return;
        //                         })
        //                 }}
        //                 className="bg-green-600 text-white px-2 py-3"
        //             >
        //                 <FaTrash />
        //             </button>
        //         );
        //     },
        // },
    ];


    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getSingleBiomassBatch = async () => {
        try {
            const { data } = await axiosInstance.get(`/biomass_batch/admin/${id}`);
            console.log("data", data?.data);
            return data?.data;

        } catch (error) {
            console.error("Error, fetching all batch details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<
        AllBatchDetailsObj[] | undefined,
        any,
        AllBatchDetailsObj[],
        any[]
    >(
        ["getSingleBiomassBatch", pageIndex, cPageSize, cSortBy, desc, q],
        getSingleBiomassBatch
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="users h-full w-full">

            <div className="all-users">
                <header className="flex items-center justify-between mb-8">

                    <div className="left">
                        <SearchBox
                            searchValue={q}
                            setSearchValue={setQ}
                            handleSearch={() => { }}
                        />

                        <span className="btn-primary flex items-center mt-5 w-48">
                            Selected Row : {selectedRows.length}
                        </span>

                    </div>


                </header>

                {component !== null && component}
            </div>
            {/* <nav className="users-nav my-12 noselect">
                <ul className="flex gap-x-6 items-center">
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to={data ? `viewPreparation/${data[0]?.preparation_id}` : "#"}
                        >
                            View Preparation Details
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to={data ? `viewPyrolysisDetail/${data[0]?.pyrolysis_id}` : "#"}
                        >
                            View Pyrolysis Detail
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            // to="viewBioCharDetails"
                            to={data ? `viewBioCharDetails/${data[0]?.biochar_id}` : "#"}

                        >
                            View BioChar Details
                        </NavLink>
                    </li>

                  

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="viewPyrolysisKilnDetails"
                        >
                            View Pyrolysis Kiln Details
                        </NavLink>
                    </li>

                </ul>
            </nav> */}

        </div>
    );
};

export default AllBatchDetails;
