import { useState } from "react";
import { NavLink } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ADD_CATEGORY_MODAL,
  EDIT_ALL_CATEGORIES_MODAL,
} from "../../types/constants";
import { FaPen } from "react-icons/fa";
import { toast } from "react-toastify";

interface UserObj {
  id: string;
  category: string;
  is_biomass: boolean;
}

const AllCategories = () => {
  const queryClient = useQueryClient();

  const handleArchival = async (id: string, category: string, is_biomass: boolean) => {
    try {
      const response = await axiosInstance.delete(`/admin/productcategories/${id}`);
      console.log(response);
      toast.success("Category Archived Successfully");
      queryClient.invalidateQueries(["getCategories"]);
    } catch (error) {
      console.error(error);
    }
  };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    // {
    //   Header: "Category ID",
    //   accessor: "id",
    //   Cell: ({ row }: any) => {
    //     const id = row?.original.id;
    //     return (
    //       <NavLink
    //         to={`${row?.original.id}`}
    //         className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
    //         title={`View Categories Details`}
    //       >
    //         {id}
    //       </NavLink>
    //     );
    //   },
    // },
    {
      Header: "Title",
      accessor: "category",
      Cell: ({ value }: any) => {
        return value === " " ? "Not Available" : value;
      },
    },
    {
      Header: "Is Biomass",
      accessor: "is_biomass",
      Cell: ({ value }: any) => {
        return value ? "Yes" : "No";
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_ALL_CATEGORIES_MODAL,
                  modalTitle: "Edit Category",
                  modalProps: {
                    categoryId: row?.original?.id,
                    category: row?.original?.category,
                    addressId: row?.original?.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <FaPen />
          </button>
        );
      },
    },
    {
      Header: "Archive",
      accessor: "archive",
      Cell: ({ value, row }: any) => {
        return (
          <button
            onClick={() => handleArchival(row?.original.id, row?.original?.category, row?.original?.is_biomass)}
            className="bg-green-600 text-white px-4 py-2 rounded"
          >
            Archive
          </button>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getCategories = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`/products/categories`);

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getCategories", pageIndex, cPageSize, cSortBy, desc, q], getCategories);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          <span className="btn-primary flex items-center mt-5 w-48">
            Selected Row : {selectedRows.length}
          </span>

        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_CATEGORY_MODAL,
                  modalTitle: "Add a Product Category",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllCategories;
