import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import Dashboard from "../Dashboard";
import { BIOMASS_BATCH_STATUS, ERROR, SUCCESS } from "../../types/constants";
import { addToast } from "../../redux/features/toastSlice";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import useIsAdmin from "../../hooks/useIsAdmin";
import { FaTrash } from "react-icons/fa";

interface BatchObj {
  status: string;
  biomass_batch_id: string;
  name: string;
  companyName: string;
  createdAt: string;
}

interface UserProps {
  otherUrl?: string;
}


const AllBatch = ({ otherUrl }: UserProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { isAdmin } = useIsAdmin();

  // const getPreparationDetails = async (value: string) => {
  //   try {
  //     const res = await axiosInstance.get(
  //       "/biomass_batch/preparation/" + value
  //     );


  //     dispatch(
  //       showModal({
  //         modalType: VIEW_PREPARATION_MODAL,
  //         modalTitle: "View Preparation Details",
  //         modalProps: {
  //           data: res.data.data,
  //         },
  //       })
  //     );
  //   } catch (error: any) {
  //     console.log(error);
  //     toast.error("Something went wrong");
  //   }
  // };
  // const getPyrolysisDetails = async (value: string) => {
  //   try {
  //     const res = await axiosInstance.get("/biomass_batch/pyrolysis/" + value);
  //     dispatch(
  //       showModal({
  //         modalType: VIEW_PYROLYSIS_MODAL,
  //         modalTitle: "View Pyrolysis Details",
  //         modalProps: {
  //           data: res.data.data,
  //         },
  //       })
  //     );
  //   } catch (error: any) {
  //     console.log(error);
  //     toast.error("Something went wrong");
  //   }
  // };

  // const getBioCharDetails = async (value: string) => {
  //   try {
  //     const res = await axiosInstance.get("/biomass_batch/biochar/" + value);
  //     dispatch(
  //       showModal({
  //         modalType: VIEW_BIOCHAR_MODAL,
  //         modalTitle: "View BioChar Details",
  //         modalProps: {
  //           data: res.data.data,
  //         },
  //       })
  //     );
  //   } catch (error: any) {
  //     console.log(error);
  //     toast.error("Something went wrong");
  //   }
  // };

  // const getSequestrationDetails = async (batchId: string, value: string) => {
  //   try {
  //     const res = await axiosInstance.get(
  //       `/biomass_batch/sequestration/${batchId}/${value}`
  //     );

  //     dispatch(
  //       showModal({
  //         modalType: VIEW_SEQUESTRATION_MODAL,
  //         modalTitle: "View Sequestration Details",
  //         modalProps: {
  //           data: res.data.data,
  //         },
  //       })
  //     );
  //   } catch (error: any) {
  //     console.log(error);
  //     toast.error("Something went wrong");
  //   }
  // };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    {
      Header: "Batch ID",
      accessor: "biomass_batch_id",
      Cell: ({ row }: any) => {
        const biomass_batch_id = row?.original?.biomass_batch_id || "Not Available";
        return (
          <NavLink
            to={biomass_batch_id ? `${biomass_batch_id}` : "#"}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title="View BioChar Details"
          >
            {biomass_batch_id}
          </NavLink>
        );
      },
    },
    {
      Header: "BioChar ID",
      accessor: "biochar_id",
      Cell: ({ row }: any) => {
        const biochar_batch_id = row?.original?.biomass_batch_id || "Not Available";
        const biochar_id = row?.original?.biochar_id || "Not Available";

        return (
          <NavLink
            to={biochar_batch_id ? `${biochar_batch_id}` : "#"}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title="View BioChar Details"
          >
            {biochar_id}
          </NavLink>
        );
      },
    },
    {
      Header: "Project ID",
      accessor: "project_id",
      Cell: ({ row }: any) => {
        const project_id = row?.original.project_id;
        return project_id === null || "" || !project_id ? (
          <p>Not Available</p>
        ) : (
          <span className="text-green px-2 py-3">
            {project_id}
          </span>
        );
      },
    },
    {
      Header: "Artisan ID",
      accessor: "artisan_id",
      Cell: ({ row }: any) => {
        const artisan_id = row?.original.artisan_id;
        return artisan_id === null || "" || !artisan_id ? (
          <p>Not Available</p>
        ) : (
          <span className="text-green px-2 py-3">
            {artisan_id}
          </span>
        );
      },
    },
    {

      Header: "Pyrolysis Kiln ID",
      accessor: "pyrolysis_kiln_id",
      Cell: ({ row }: any) => {
        const pyrolysis_kiln_id = row?.original.pyrolysis_kiln_id;
        return pyrolysis_kiln_id === null ? (
          <p>Not Available</p>
        ) : (
          <span className="text-green px-2 py-3">
            {pyrolysis_kiln_id}
          </span>
        );
      },
    },
    {
      Header: "Pyrolysis Kiln Name",
      accessor: "kiln_name",
      Cell: ({ row }: any) => {
        const kiln_name = row?.original.kiln_name;
        return kiln_name === null ? (
          <p >Not Available</p>
        ) : (
          <span
            className="text-green px-2 py-3"
          >
            {kiln_name}

          </span>
        );
      },
    },
    {
      Header: "Date of Submission",
      accessor: "biochar_created_at",
      Cell: ({ value }: any) => {
        return value ? new Date(value).toLocaleString() : "Invalid Date";
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }: any) => {
        const statusColors: Record<string, string> = {
          submitted: "bg-blue-500 text-white",
          draft: "bg-yellow-500 text-white ",
          sequestered: "bg-yellow-700 text-white",
          accepted: "bg-green-500 text-white",
          cancelled: "bg-red-500 text-white",
          deleted: "bg-gray-500 text-white",
          completed: "bg-green-500 text-white",
        };
        const displayValue = value ? value.toUpperCase() : "Not Available";
        return (
          <span
            className={`px-2 py-1 text-sm rounded-full ${statusColors[value] || "bg-gray-300 text-black"}`}
          >
            {displayValue}
          </span>
        );
      },
    },

    // {
    //   Header: "View Preparation",
    //   accessor: "preparation_id",
    //   Cell: ({ value }: any) => {
    //     return (
    //       <div>
    //         {value ? (
    //           <p
    //             onClick={() => {
    //               getPreparationDetails(value);
    //             }}
    //             className="text-green-400 cursor-pointer"
    //           >
    //             <button className="bg-green-600 text-white px-2 py-3">
    //               <FaEye />
    //             </button>
    //           </p>
    //         ) : (
    //           <p className="text-red-400">Not Available</p>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   Header: "View Pyrolysis Detail",
    //   accessor: "pyrolysis_id",
    //   Cell: ({ value }: any) => {
    //     return (
    //       <div>
    //         {value ? (
    //           <p
    //             onClick={() => {
    //               getPyrolysisDetails(value);
    //             }}
    //             className="text-green-400 cursor-pointer"
    //           >
    //             <button className="bg-green-600 text-white px-2 py-3">
    //               <FaEye />
    //             </button>
    //           </p>
    //         ) : (
    //           <p className="text-red-400">Not Available</p>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   Header: "View BioChar Details",
    //   accessor: "biochar_id",
    //   Cell: ({ value }: any) => {
    //     return (
    //       <div>
    //         {value ? (
    //           <p
    //             onClick={() => {
    //               getBioCharDetails(value);
    //             }}
    //             className="text-green-400 cursor-pointer"
    //           >
    //             <button className="bg-green-600 text-white px-2 py-3">
    //               <FaEye />
    //             </button>
    //           </p>
    //         ) : (
    //           <p className="text-red-400">Not Available</p>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   Header: "View Sequestration Details",
    //   accessor: "sequestration_id",
    //   Cell: ({ value, row }: any) => {
    //     return (
    //       <div>
    //         {value ? (
    //           <p
    //             onClick={() => {
    //               getSequestrationDetails(row.original.biomass_batch_id, value);
    //             }}
    //             className="text-green-400 cursor-pointer"
    //           >
    //             <button className="bg-green-600 text-white px-2 py-3">
    //               <FaEye />
    //             </button>
    //           </p>
    //         ) : (
    //           <p className="text-red-400">Not Available</p>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   Header: "Verify",
    //   accessor: "verify",
    //   Cell: ({ row }: any) => {
    //     const currentStatus = row?.original?.status;
    //     const biomass_batch_id = row?.original?.biomass_batch_id;

    //     const handleStatusUpdate = () => {
    //       if (!biomass_batch_id) {
    //         dispatch(
    //           addToast({
    //             kind: ERROR,
    //             msg: "Error: Biochar ID is missing.",
    //           })
    //         );
    //         return;
    //       }
    //       const nextStatus = currentStatus === "cancelled" ? "accepted" : "cancelled";
    //       axiosInstance
    //         .patch(`/biomass_batch/status/${biomass_batch_id}`, {
    //           status: nextStatus
    //         })
    //         .then((res) => {
    //           dispatch(
    //             addToast({
    //               kind: SUCCESS,
    //               msg: "Biomass Batch status updated successfully",
    //             })
    //           );
    //           queryClient.invalidateQueries("getAllBiomassBatch");
    //         })
    //         .catch((error: AxiosError) => {
    //           console.log(error.response);
    //           dispatch(
    //             addToast({
    //               kind: ERROR,
    //               msg: `Error: ${error.response?.data?.msg || "Failed to update status"}`,
    //             })
    //           );
    //         });
    //     };
    //     return (
    //       <button onClick={handleStatusUpdate}>
    //         {currentStatus === "cancelled" ? <span className="bg-green-500 px-4 py-2 text-white">Accept</span> : <span className="bg-red-500 px-4 py-2 text-white">Reject</span>}
    //       </button>
    //     );
    //   }
    // }
  ];
  if (isAdmin) {
    ALL_USERS_COLUMNS.push(
      {
        Header: "Verify",
        accessor: "verify",
        Cell: ({ row }: any) => {
          const currentStatus = row?.original?.status;
          const biomass_batch_id = row?.original?.biomass_batch_id;

          const updateStatus = (newStatus: string) => {
            if (!biomass_batch_id) {
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Error: Biochar ID is missing.",
                })
              );
              return;
            }
            axiosInstance
              .patch(`/biomass_batch/status/${biomass_batch_id}`, { status: newStatus })
              .then((res) => {
                dispatch(
                  addToast({
                    kind: SUCCESS,
                    msg: "Biomass Batch status updated successfully",
                  })
                );
                queryClient.invalidateQueries("getAllBiomassBatch");
              })
              .catch((error: AxiosError) => {
                console.log(error.response);
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: `Error: ${error.response?.data?.msg || "Failed to update status"}`,
                  })
                );
              });
          };

          return (
            <div>
                {currentStatus !== BIOMASS_BATCH_STATUS.CANCELLED &&
                currentStatus !== BIOMASS_BATCH_STATUS.DELETED &&
                currentStatus !== BIOMASS_BATCH_STATUS.ACCEPTED && (
                  <>
                  <button
                    onClick={() => updateStatus(BIOMASS_BATCH_STATUS.ACCEPTED)}
                    className="block w-full mb-2 px-4 py-2 text-white bg-green-500 rounded"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => updateStatus(BIOMASS_BATCH_STATUS.CANCELLED)}
                    className="block w-full px-4  py-2 text-white bg-red-500 rounded"
                  >
                    Reject
                  </button>
                  </>
                )}

                {currentStatus === BIOMASS_BATCH_STATUS.ACCEPTED && (
                <button
                  onClick={() => updateStatus(BIOMASS_BATCH_STATUS.CANCELLED)}
                  className="block w-full px-4 py-2 text-white bg-red-500 rounded"
                >
                  Reject
                </button>
                )}

                {currentStatus === BIOMASS_BATCH_STATUS.CANCELLED && (
                <button
                  onClick={() => updateStatus(BIOMASS_BATCH_STATUS.ACCEPTED)}
                  className="block w-full px-4 py-2 text-white bg-green-500 rounded"
                >
                  Accept
                </button>
                )}
           </div>
          );
        }
      }

      // }
    );
  }
  if (isAdmin  ) {
    ALL_USERS_COLUMNS.push(
      {
        Header: "Delete",
        accessor: "deleteMaterial",
        Cell: ({ value, row }: any) => {
          const currentStatus: string = row?.original?.status;
          return (
            currentStatus !== BIOMASS_BATCH_STATUS.DELETED ? <button
              onClick={() => {
                const biomass_batch_id = row?.original?.biomass_batch_id;
                if (!biomass_batch_id) {
                  dispatch(
                    addToast({
                      kind: ERROR,
                      msg: "Error: BioChar ID is missing.",
                    })
                  );
                  return;
                }
                if (!window.confirm("Are you sure you want to delete this BioChar Batch?")) {
                  return;
                }
                axiosInstance.delete(`/biomass_batch/${biomass_batch_id}`)
                  .then((res) => {

                    dispatch(
                      addToast({
                        kind: SUCCESS,
                        msg: "BioChar Deleted Successfully",
                      })
                    );
                    queryClient.invalidateQueries("getAllBiomassBatch")
                  }).catch((error: AxiosError) => {
                    console.log(error.response);
                    dispatch(
                      addToast({
                        kind: ERROR,
                        msg: `Error: ${error.response?.data?.msg || "Failed to delete BioChar"}`,
                      })
                    );
                    return;
                  })
              }}
              className="bg-green-600 text-white px-2 py-3"
            >
              <FaTrash />
            </button> : null
          );
        },
      },
    );
  }

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllBiomassBatch = async () => {
    const { data } = await axiosInstance.get(otherUrl || `/biomass_batch/admin/all`);
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    BatchObj[] | undefined,
    any,
    BatchObj[],
    any[]
  >(
    ["getAllBiomassBatch", pageIndex, cPageSize, cSortBy, desc, q],
    getAllBiomassBatch
  );

  const statusFilterOptions = Object.values(BIOMASS_BATCH_STATUS).map((status) => ({
    value: status,
    label: status.toUpperCase(),
  }));
  
  const filterOptions = {
    status: statusFilterOptions, // Only applying a filter on the "status" column
  };

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <div className="mb-5">
        <Dashboard isBioChar={true}/>
      </div>
      <header className="flex items-center justify-between mb-8">

        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          <span className="btn-primary flex items-center mt-5 w-48">
            Selected Row : {selectedRows.length}
          </span>
        </div>

      </header>

      {component !== null && component}
    </div>
  );
};

export default AllBatch;
