import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
  AZURE_STORAGE_DIRECTORY,
  AZURE_STORAGE_SUBDIRECTORY,
  ERROR, SUCCESS
} from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface FormValues {
  pit_name: string;
  pit_length: number;
  pit_width: number;
  pit_height: number;
  pit_photo: string;
  address: string;
}

export const LENGTH_UNIT: { value: string; label: string }[] = [
  { value: "mm", label: "mm" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
];

const AddSoilPitModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [img, setImg] = useState<Object | null>(null);
  const [lengthUnit, setLengthUnit] = useState("cm");
  const [widthUnit, setWidthUnit] = useState("cm");
  const [heightUnit, setHeightUnit] = useState("cm");

  const initialValues: FormValues = {
    pit_name: "",
    pit_length: 0,
    pit_width: 0,
    pit_height: 0,
    pit_photo: "",
    address: "",
  };

  const validationSchema = Yup.object({
    pit_name: Yup.string().trim().required("Pit Name is required"),
    pit_length: Yup.number()
      .min(0.002, "Pit Length must be at least 0.002")
      .required("Pit Length is required"),
    pit_width: Yup.number()
      .min(0.002, "Pit Width must be at least 0.002")
      .required("Pit Width is required"),
    pit_height: Yup.number()
      .min(0.002, "Pit Height must be at least 0.002")
      .required("Pit Height is required"),
    address: Yup.string().trim().required("Address is required"),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleSubmit = async (soil_pit: FormValues) => {
    let lat = "12.971599";
    let long = "77.594566";
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((pos) => {
        lat = pos.coords.latitude.toString();
        long = pos.coords.longitude.toString();
      });
    }
    if (!img) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Image is required",
        })
      );
      return;
    }
    setIsSubmitting(true);
    try {
      const imgRes = await uploadImage(img, AZURE_STORAGE_DIRECTORY.SOIL_PIT, AZURE_STORAGE_SUBDIRECTORY.PROFILE);
      if (imgRes.data.publicUrl) {
        const obj = {
          ...soil_pit,
          pit_photo: imgRes.data.publicUrl,
          lat: lat,
          lng: long,
        };

        const result = await axiosInstance.post("/soil_pit/create", obj);
        if (result) {

          setIsSubmitting(false);
          queryClient.invalidateQueries(["getAllSoilPitDetails"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Soil Pit added successfully!",
            })
          );
          dispatch(hideModal());
        }
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
          <div className="flex item-center gap-x-6">
            <Input label="Pit Name" id="pit_name" name="pit_name" type="text" />
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input label={`Pit Length (${lengthUnit})`} id="pit_length" name="pit_length" type="number" />
            </div>
            <div>
              <select
                value={lengthUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(lengthUnit, newUnit);
                  setFieldValue("pit_length", values.pit_length * conversionFactor);
                  setLengthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input label={`Pit Width (${widthUnit})`} id="pit_width" name="pit_width" type="number" />
            </div>
            <div>
              <select
                value={widthUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(widthUnit, newUnit);
                  setFieldValue("pit_width", values.pit_width * conversionFactor);
                  setWidthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input label={`Pit Height (${heightUnit})`} id="pit_height" name="pit_height" type="number" />
            </div>
            <div>
              <select
                value={heightUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(heightUnit, newUnit);
                  setFieldValue("pit_height", values.pit_height * conversionFactor);
                  setHeightUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex item-center gap-x-6">
            <Input label="Address" id="address" name="address" type="text" />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Upload Soil Pit Photo"
              id="pit_photo"
              name="pit_photo"
              type="file"
              accept=".jpg,.png,.jpeg,.wbep"
              disabled={!!img}
              onChange={(e) => {
                if (e.target.files) {
                  setImg(e.target.files[0]);
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Add Soil Pit"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSoilPitModal;
