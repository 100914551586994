import { useState } from "react";
import { FaPen } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { NavLink, Outlet, useParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import InfoCard from "../components/Accordian-Cards/InfoCard";
import SearchBox from "../components/SearchBox/SearchBox";
import TableInstance from "../components/Table/TableInstance";
import { addToast } from "../redux/features/toastSlice";
import { showModal } from "../redux/features/modalSlice";
import {
    EDIT_FPO_MODAL,
} from "../types/constants";
import { SUCCESS, ERROR } from "../types/constants";

interface FPOObj {
    fpo_id: string;
    fpo_name: string;
    fpo_number: string;
    fpo_email: string;
    cin: string;
    gst_number: string;
    createdAt: string;
    is_active: boolean;
    password: string;
}

interface FPODetailsObj {
    total_users: number;
    pending_approvals: number;
    total_biomass_batches: number;
    password: string;
}

function FPOLayout() {
    const { id } = useParams();

    const ALL_USERS_COLUMNS = [
        {
            Header: "FPO ID",
            accessor: "fpo_id",
        },
        {
            Header: "Name",
            accessor: "fpo_name",
        },
        {
            Header: "Email",
            accessor: "fpo_email",
        },
        {
            Header: "Contact",
            accessor: "fpo_number",
        },
        {
            Header: "CIN",
            accessor: "cin",
        },
        {
            Header: "GST Number",
            accessor: "gst_number",
        },
        {
            Header: "Password",
            accessor: "password",
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: ({ row }: any) => {
                return (
                    <button
                        onClick={() => {
                            console.log(row);
                            dispatch(
                                showModal({
                                    modalType: EDIT_FPO_MODAL,
                                    modalTitle: "Edit Details",
                                    modalProps: {
                                        fpo_id: row?.original.fpo_id,
                                        fpo_name: row?.original.fpo_name,
                                        fpo_number: row?.original.fpo_number,
                                        fpo_email: row?.original.fpo_email,
                                        cin: row?.original.cin,
                                        gst_number: row?.original.gst_number,
                                        address: row?.original?.addresses?.at(0)?.address,
                                        is_active: row?.original.is_active,
                                        city: row?.original?.addresses[0]?.city,
                                        state: row?.original?.addresses[0]?.state,
                                        pincode: row?.original?.addresses[0]?.pincode,
                                        enrolled_members: row?.original.enrolled_members,
                                        project_type: row?.original.project_type,
                                        land_holding: row?.original.land_holding,
                                    },
                                })
                            );
                        }}
                        className="bg-green-600 text-white px-2 py-3"
                    >
                        <FaPen />
                    </button>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) => {
                return new Date(value).toLocaleString();
            },
        },
        {
            Header: "Updated At",
            accessor: "updated_at",
            Cell: ({ value }: any) => {
                return new Date(value).toLocaleString();
            },
        },
        {
            Header: "Active",
            accessor: "is_active",
            Cell: ({ row }: any) => {
                const [active, setActive] = useState(row.original.is_active);

                const toggleActiveStatus = async () => {
                    try {
                        const updatedStatus = !active;
                        setActive(updatedStatus);

                        const response = await axiosInstance.patch(`/fpo/update/`, {
                            is_active: updatedStatus,
                            fpo_id: row?.original.fpo_id,
                        });
                        if (response.status === 200) {
                            dispatch(
                                addToast({
                                    kind: SUCCESS,
                                    msg: updatedStatus
                                        ? "FPO Status is Active"
                                        : "FPO Status is Deactive",
                                })
                            );
                        }
                        queryClient.invalidateQueries(["getAllFpos"]);
                    } catch (error: any) {
                        if (error.response) {
                            const { msg } = error.response.data;
                            switch (error.response.status) {
                                case 400:
                                case 500:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: msg,
                                        })
                                    );
                                    break;
                                default:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "Oops, something went wrong",
                                        })
                                    );
                                    break;
                            }
                        } else if (error.request) {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "No response received from the server",
                                })
                            );
                        } else {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: `Error: ${error.message}`,
                                })
                            );
                        }
                    }
                };

                return (
                    <div
                        className={`relative inline-block w-14 h-7 rounded-full ${active ? "bg-green-500" : "bg-gray-400"
                            } cursor-pointer`}
                        onClick={toggleActiveStatus}
                    >
                        <span
                            className={`absolute left-1 top-1 w-5 h-5 rounded-full bg-white transition-transform ${active ? "translate-x-7" : "translate-x-0"
                                }`}
                        />
                        <span
                            className={`absolute left-2 top-1/2 transform -translate-y-1/2 text-xs font-semibold ${active ? "text-white" : "text-transparent"
                                }`}
                        >
                            Yes
                        </span>
                        <span
                            className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-xs font-semibold ${active ? "text-transparent" : "text-gray-600"
                                }`}
                        >
                            No
                        </span>
                    </div>
                );
            },
        },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const queryClient = useQueryClient();

    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getAllFpos = async ({ queryKey }: any) => {
        const { data } = await axiosInstance.get(`/fpo/all`);
        const fpoData = await axiosInstance.get(`/fpo/detail/${id}`);

        let requiredData = [];

        for (let item of data.data) {
            if (item.fpo_id === id) {
                requiredData.push(item);
                break;
            }
        }
        const pass = fpoData.data.data.password;
        requiredData[0].password = pass;

        return requiredData;
    };

    const { data, error, isLoading } = useQuery<
        FPOObj[] | undefined,
        any,
        FPOObj[],
        any[]
    >(["getAllFpos", pageIndex, cPageSize, cSortBy, desc, q], getAllFpos);

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    let CardComponent = null;

    const getFpoDetails = async ({ queryKey }: any) => {
        const fpoData = await axiosInstance.get(`/fpo/detail/${id}`);

        return fpoData.data.data;
    };

    const {
        data: fpoData,
        // error: fpoError,
        // isLoading: fpoIsLoading,
    } = useQuery<FPODetailsObj | undefined, any, FPODetailsObj, any[]>(
        ["getFpoDetails", pageIndex, cPageSize, cSortBy, desc, q],
        getFpoDetails
    );

    if (error) {
        CardComponent = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        CardComponent = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        CardComponent = (
            <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-7">
                    <InfoCard
                        title="Total Users"
                        value={fpoData?.total_users}
                    />
                    <InfoCard
                        title="Pending Approvals"
                        value={fpoData?.pending_approvals}
                    />
                    <InfoCard
                        title="Total Biomass Batches"
                        value={fpoData?.total_biomass_batches}
                    />
                </div>
            </>
        );
    }

    return (
        <div className="users h-full w-full">
            <div className="all-users">
                <header className="flex items-center justify-between mb-8">
                    <div className="left">
                        <SearchBox
                            searchValue={q}
                            setSearchValue={setQ}
                            handleSearch={() => { }}
                        />
                    </div>
                </header>

                {component !== null && component}

                {CardComponent !== null && CardComponent}
            </div>

            <nav className="users-nav my-12 noselect">
                <ul className="flex gap-x-6 items-center">
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to=""
                        >
                            Seller
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="buyers"
                        >
                            General User
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="artisans"
                        >
                            Artisan
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="product"
                        >
                            Products
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="transactions"
                        >
                            Transactions
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="compostMaterial"
                        >
                            Compost Material
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="measuringVessel"
                        >
                            ⁠⁠Measuring Vessel
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="soilPit"
                        >
                            ⁠⁠Soil Pit
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive
                                    ? "bg-primary text-white"
                                    : "bg-white"
                                }`
                            }
                            end
                            to="pyrolysisKiln"
                        >
                            ⁠⁠Pyrolysis Kilns
                        </NavLink>
                    </li>

                </ul>
            </nav>

            <div className="content">
                <Outlet />
            </div>
        </div>
    );
}

export default FPOLayout;
