import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import TableInstance from "../../components/Table/TableInstance";
import {
    ERROR,
    SUCCESS
} from "../../types/constants";
import { addToast } from "../../redux/features/toastSlice";
import { AxiosError } from "axios";
import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";


interface CompostMaterialDetailsObj {
    material_id: number;
    material_name: string;
}
interface CompostMaterialDetailsProps {
    otherUrl?: string;
}

const CompostMaterialDetails = ({ otherUrl }: CompostMaterialDetailsProps) => {
    const queryClient = useQueryClient();
    const { id } = useParams();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No.",
            accessor: 'sr_no',
            Cell: ({ value, row }: any) => {
                return (
                    <>
                        {row.index + 1}
                    </>)
            }
        },
        {
            Header: "Material ID",
            accessor: "material_id",
        },
        {
            Header: "Material Name",
            accessor: "material_name",
            Cell: ({ row }: any) => row?.original?.material_name || "Not Available",
        },
        
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getCompostMaterials = async (queryKey: string) => {
        const { data } = await axiosInstance.get(otherUrl || `/biomass_batch/sequestration/compost_material/all`, {
            params: id ? { material_id: id } : {},
        });
        return data.data.filter((item: { material_id: number }) => item.material_id === Number(id));
    };

    const { data, error, isLoading } = useQuery<
        CompostMaterialDetailsObj[] | undefined,
        any,
        CompostMaterialDetailsObj[],
        any[]
    >(["getCompostMaterials", pageIndex, cPageSize, cSortBy, desc, q], () => getCompostMaterials(q));

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1} // do calculation here
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            {component !== null && component}
        </div>
    );
};

export default CompostMaterialDetails;
