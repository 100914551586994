import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS, USER_ROLES_CREATION } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { act } from "react-dom/test-utils";

interface UserObj {
  name: string;
  is_biomass: boolean;
}

const AddCategoryModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [active, setActive] = useState(false);

  const toggleActiveStatus =  () => {
   
          const updatedStatus = !active;
          setActive(updatedStatus);

  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const editAccessOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];
  const userRoleOptions = Object.values(USER_ROLES_CREATION).map(
    (r: string) => {
      return {
        label: r,
        value: r,
      };
    }
  );

  const initialValues: UserObj = {
    name: "",
    is_biomass: false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Category Title is Required"),
  });

  const handleSubmit = useCallback((user: UserObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/admin/productcategories`,
        {
          category: user?.name,
          is_biomass: active,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getCategories"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Product Category Added Successfully!",
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
       {({ setFieldValue }) => (
      <Form
        className="px-6 py-4 mt-2"
        // style={{
        //   minWidth: "360px",
        //   maxWidth: "760px",
        // }}
        style={{ minWidth: "40vw" }}
      >
        <div className="flex flex-col item-center gap-x-6">
          <div>
          <Input label="Category Title" id="name" name="name" type="text" />
          </div>
          <div className="flex gap-x-6 flex-row">
          <div
                        className={`relative inline-block w-14 h-7 rounded-full ${active ? "bg-green-500" : "bg-gray-400"
                            } cursor-pointer`}
                        onClick={toggleActiveStatus}
                    >
                        <span
                            className={`absolute left-1 top-1 w-5 h-5 rounded-full bg-white transition-transform ${active ? "translate-x-7" : "translate-x-0"
                                }`}
                        />

                        <span
                            className={`absolute left-2 top-1/2 transform -translate-y-1/2 text-xs font-semibold ${active ? "text-white" : "text-transparent"
                                }`}
                        >
                            Yes
                        </span>

                        <span
                            className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-xs font-semibold ${active ? "text-transparent" : "text-gray-600"
                                }`}
                        >
                            No
                        </span>
                    </div>
         <div><p>Mark as BioMass Category</p></div> 
          </div>
        </div>


        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            // classes="text-sm"
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>

      </Form>)}
    </Formik>
  );
};

export default AddCategoryModal;
