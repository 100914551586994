import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface CropObj {
  name: string;
  type: string;
}

type EditAllCropsProps = {
  cropId: string;
  name: string;
  type: string;
  addressId: string;
};

const EditAllCropsModal: React.FC<EditAllCropsProps> = ({
  cropId,
  name,
  type,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: CropObj = {
    name: name,
    type: type,
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Name is Required"),
    type: Yup.string().trim().required("Type is Required"),
  });

  const handleSubmit = useCallback(
    async (crop: CropObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/farming/crops/update/${cropId}`,
          {
            name: crop.name,
            type: crop.type,
          },
          { headers: { ContentType: "application/json" } }
        );

        queryClient.invalidateQueries(["getProducts"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Crop Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [cropId, queryClient, dispatch, addressId]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        // style={{
        //   minWidth: "360px",
        //   maxWidth: "760px",
        // }}
        style={{ minWidth: "40vw" }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Name" id="name" name="name" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Type" id="type" name="type" type="text" />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            // classes="text-sm"
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default EditAllCropsModal;
