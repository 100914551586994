import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import ImagePreview from "../ModalComponents/ImagePreview";
import { uploadImage } from "../../http/uploadImage";

interface PyrolysisKilnObj {
  kiln_name: string;
  kiln_type: string;
  depth: number;
  upper_diameter: number;
  lower_diameter: number;
  kiln_photo: string;
}

type EditPyrolysisKilnModalProps = {
  pyrolysisKilnId: string;
  kiln_name: string;
  kiln_type: string;
  depth: number;
  upper_diameter: number;
  lower_diameter: number;
  kiln_photo: string;
};

export const LENGTH_UNIT: { value: string; label: string }[] = [
  { value: "mm", label: "mm" },
  { value: "cm", label: "cm" },
  { value: "m", label: "m" },
];

const EditPyrolysisKilnModal: React.FC<EditPyrolysisKilnModalProps> = ({
  pyrolysisKilnId,
  kiln_name,
  kiln_type,
  depth,
  upper_diameter,
  lower_diameter,
  kiln_photo,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImg, setNewImg] = useState<File | null>(null);
  const [depthUnit, setDepthUnit] = useState("cm");
  const [upperDiameterUnit, setUpperDiameterUnit] = useState("cm");
  const [lowerDiameterUnit, setLowerDiameterUnit] = useState("cm");

  const initialValues: PyrolysisKilnObj = {
    kiln_name,
    kiln_type,
    depth,
    upper_diameter,
    lower_diameter,
    kiln_photo,
  };

  const validationSchema = Yup.object({
    kiln_name: Yup.string().trim().required("Kiln Name is required"),
    kiln_type: Yup.string().trim().required("Kiln Type is required"),
    depth: Yup.number()
      .min(0.002, "Depth must be at least 0.002")
      .required("Depth is required"),
    upper_diameter: Yup.number()
      .min(0.002, "Upper Diameter must be at least 0.002")
      .required("Upper Diameter is required"),
    lower_diameter: Yup.number()
      .min(0.002, "Lower Diameter must be at least 0.002")
      .required("Lower Diameter is required"),
    kiln_photo: Yup.string().required("Kiln Photo is required"),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleSubmit = useCallback(
    async (pyrolysisKiln: PyrolysisKilnObj) => {
      setIsSubmitting(true);
      try {
        let finalPhotoUrl = kiln_photo;
        if (newImg) {
          const imgRes = await uploadImage(newImg, AZURE_STORAGE_DIRECTORY.PYROLYSIS_KILN, AZURE_STORAGE_SUBDIRECTORY.PROFILE);
          if (imgRes.data.publicUrl) {
            finalPhotoUrl = imgRes.data.publicUrl;
          } else {
            throw new Error("Image upload failed");
          }
        }

        await axiosInstance.patch(
          `/pyrolysis_kiln/update/${pyrolysisKilnId}`,
          {
            kiln_name: pyrolysisKiln.kiln_name,
            kiln_type: pyrolysisKiln.kiln_type,
            depth: pyrolysisKiln.depth,
            upper_diameter: pyrolysisKiln.upper_diameter,
            lower_diameter: pyrolysisKiln.lower_diameter,
            kiln_photo: finalPhotoUrl,
          },
          { headers: { ContentType: "application/json" } }
        );

        queryClient.invalidateQueries(["getAllPyrolysisKiln"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Pyrolysis Kiln Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const { msg } = error.response.data;
          const errorMsg = [400, 500].includes(error.response.status) ? msg : "Oops, something went wrong";
          dispatch(
            addToast({
              kind: ERROR,
              msg: errorMsg,
            })
          );
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [dispatch, queryClient, pyrolysisKilnId, newImg, kiln_photo]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
          <div className="flex items-center gap-x-6">
            <Input label="Kiln Name" id="kiln_name" name="kiln_name" type="text" />
          </div>

          <div className="flex items-center gap-x-6">
            <Input label="Kiln Type" id="kiln_type" name="kiln_type" type="text" />
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input label={`Depth (${depthUnit})`} id="depth" name="depth" type="number" />
            </div>
            <div>
              <select
                value={depthUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(depthUnit, newUnit);
                  setFieldValue("depth", values.depth * conversionFactor);
                  setDepthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input label={`Upper Diameter (${upperDiameterUnit})`} id="upper_diameter" name="upper_diameter" type="number" />
            </div>
            <div>
              <select
                value={upperDiameterUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(upperDiameterUnit, newUnit);
                  setFieldValue("upper_diameter", values.upper_diameter * conversionFactor);
                  setUpperDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input label={`Lower Diameter (${lowerDiameterUnit})`} id="lower_diameter" name="lower_diameter" type="number" />
            </div>
            <div>
              <select
                value={lowerDiameterUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(lowerDiameterUnit, newUnit);
                  setFieldValue("lower_diameter", values.lower_diameter * conversionFactor);
                  setLowerDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="kiln_photo" className="block text-sm font-extrabold text-black">
              Preview Kiln Photo
            </label>
            <ImagePreview imageUrl={kiln_photo} altText="Kiln Photo Preview" />
          </div>

          <div className="mt-4">
            <Input
              label="Upload New Kiln Photo"
              id="new_kiln_photo"
              name="new_kiln_photo"
              type="file"
              accept=".jpg,.png,.jpeg,.webp"
              onChange={(e) => {
                if (e.target.files) {
                  setNewImg(e.target.files[0]);
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditPyrolysisKilnModal;