import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";


interface AllFertilisersObj {
  name: string;
  nitrogen: number;
  phosphorus: number;
  potassium: number;
  type: string;
}

type EditAllFertilisersProps = {
  fertiliserId: string;
  name: string;
  nitrogen: number;
  phosphorus: number;
  potassium: number;
  type: string;
  addressId: string;
};

const EditAllFertilisersModal: React.FC<EditAllFertilisersProps> = ({
  fertiliserId,
  name,
  nitrogen,
  phosphorus,
  potassium,
  type,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: AllFertilisersObj = {
    name,
    nitrogen,
    phosphorus,
    potassium,
    type,
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Fertiliser Name is Required"),
    nitrogen: Yup.number().required("Nitrogen is Required"),
    phosphorus: Yup.number().required("Phosphorus is Required"),
    potassium: Yup.number().required("Potassium is Required"),
    type: Yup.string().trim().required("Type is Required"),
  });

  const handleSubmit = useCallback(
    async (fertilisers: AllFertilisersObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.put(
          `/users/fertilizers/update/${fertiliserId}`,
          {
            name: fertilisers.name,
            nitrogen: Number(fertilisers.nitrogen),
            phosphorus: Number(fertilisers.phosphorus),
            potassium: Number(fertilisers.potassium),
            type: fertilisers.type,
          },
          { headers: { "Content-Type": "application/json" } }
        );


        queryClient.invalidateQueries(["getFertilisers"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Fertiliser Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        console.error("Error updating fertilizer:", error);
        const errorMessage =
          error.response?.data?.msg || "Error updating fertiliser";
        dispatch(
          addToast({
            kind: ERROR,
            msg: errorMessage,
          })
        );
      } finally {
        setIsSubmitting(false);
      }
    },
    [fertiliserId, addressId, dispatch, queryClient]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form
        className="px-6 py-4 mt-2"
        // style={{
        //   minWidth: "360px",
        //   maxWidth: "760px",
        // }}
        style={{ minWidth: "40vw" }}
      >
        <div className="flex flex-col gap-y-4">
          <Input
            label="Fertiliser 
          Name"
            id="name"
            name="name"
            type="text" />

          <Input
            label="Nitrogen"
            id="nitrogen"
            name="nitrogen"
            type="number" />

          <Input
            label="Phosphorus"
            id="phosphorus"
            name="phosphorus"
            type="number"
          />
          <Input
            label="Potassium"
            id="potassium"
            name="potassium"
            type="number"
          />
          <div className="mb-5">
            <Select
              options={[
                { value: "simple", label: "Simple" },
                { value: "complex", label: "Complex" },
              ]}
              label="Type"
              id="type"
              name="type"
            />
          </div>
        </div>

        <div className="buttons flex items-center w-full justify-center mt-6">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            // classes="text-sm"
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default EditAllFertilisersModal;
