import { useState } from "react";
import { NavLink } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FaPen, FaEye } from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    ADD_BIOMASS_COMPOSE_MODAL,
    EDIT_BIOMASS_CONFIG_MODAL,
    VIEW_ASSIGNED_ARTISAN,
} from "../../types/constants";
import useIsAdmin from "../../hooks/useIsAdmin";
import {
    ERROR,
    SUCCESS
} from "../../types/constants";
import { addToast } from "../../redux/features/toastSlice";
import { AxiosError } from "axios";
import { FaTrash } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";

interface BiomassObj {
    biomass_composition_id: number;
    biomass_name: string;
    biomass_blend: {
        cotton_stock: number;
        rice_straw: number;
    };
    average_biomass_diameter: string;
    average_biomass_length: string;
    biomass_photo: string;
    artisan_id_assigned: string | null;
}

const formatKey = (key: string): string => {
    return key
        .replace(/[^a-zA-Z0-9\s]/g, " ")
        .replace(/\s+/g, " ")
        .trim()
        .split(" ")
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
};

const BiomassComposition = () => {
    const { isAdmin } = useIsAdmin();
    const queryClient = useQueryClient();


    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => {
                return row.index + 1;
            },
        },
        {
            Header: "Biomass Composition Id",
            accessor: "biomass_composition_id",
            Cell: ({ row }: any) => row?.original?.biomass_composition_id || "Not Available",

        },
        {
            Header: "Biomass Name",
            accessor: "biomass_name",
            Cell: ({ row }: any) => row?.original?.biomass_name || "Not Available",
        },
        {
            Header: "Biomass Blend Details",
            accessor: "biomass_blend",
            Cell: ({ value }: any) => {
                return (
                    <div className="flex flex-col gap-2">
                        {Object.entries(value).map(([key, value]: any) => {
                            return (
                                <div className="flex flex-row ml-10 min-w-[130px] max-w-[190px]">
                                    <p className="flex flex-1 justify-start">
                                        {formatKey(key)}:
                                    </p>
                                    <p className="flex flex-1 justify-end">
                                        {value}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            Header: "Avg Diameter (cm)",
            accessor: "average_biomass_diameter",
            Cell: ({ row }: any) => row?.original?.average_biomass_diameter || "Not Available",

        },
        {
            Header: "Avg Length (cm)",
            accessor: "average_biomass_length",
            Cell: ({ row }: any) => row?.original?.average_biomass_length || "Not Available",

        },
        // {
        //     Header: "Assigned Artisan",
        //     accessor: "artisan_id_assigned",
        //     Cell: ({ value, row }: any) => {
        //         const filterData = row?.original.artisans.filter(
        //             (artisan: any) => artisan !== null
        //         );
        //         return (
        //             <>
        //                 {filterData.length > 0 ? (
        //                     <button
        //                         className="bg-green-600 text-white px-2 py-3"
        //                         onClick={() => {
        //                             dispatch(
        //                                 showModal({
        //                                     modalType: VIEW_ASSIGNED_ARTISAN,
        //                                     modalTitle: "View Assigned Artisan",
        //                                     modalProps: {
        //                                         artisans: filterData,
        //                                     },
        //                                 })
        //                             );
        //                         }}
        //                     >
        //                         <span className="mr-1">View Artisan List</span>
        //                     </button>
        //                 ) : (
        //                     <p>No artisans assigned</p>
        //                 )}
        //             </>
        //         );
        //     },
        // },
        {
            Header: "Biomass Img",
            accessor: "biomass_photo",
            Cell: ({ value }: any) => {
                return (
                    <a
                        href={value}
                        className="text-green-400"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="bg-green-600 text-white px-2 py-3">
                            <FaEye />
                        </button>
                    </a>
                );
            },
        },

    ];
    if (isAdmin) {
        ALL_USERS_COLUMNS.push(
            {
                Header: "Edit",
                accessor: "edit",
                Cell: ({ value, row }: any) => {
                    return (
                        <button
                            onClick={() => {
                                dispatch(
                                    showModal({
                                        modalType: EDIT_BIOMASS_CONFIG_MODAL,
                                        modalTitle: "Edit Biomass Config",
                                        modalProps: {
                                            biomassConfigId:
                                                row?.original
                                                    .biomass_composition_id,
                                            biomassName: row?.original.biomass_name,
                                            cottonStock:
                                                row?.original.biomass_blend
                                                    .cotton_stock,
                                            riceStraw:
                                                row?.original.biomass_blend
                                                    .rice_straw,
                                            avgBiomassDia:
                                                row?.original
                                                    .average_biomass_diameter,
                                            avgBiomassLen:
                                                row?.original
                                                    .average_biomass_length,
                                            biomassPhoto:
                                                row?.original.biomass_photo,
                                            artisanIdAssigned:
                                                row?.original.artisan_id_assigned,
                                        },
                                    })
                                );
                            }}
                            className="bg-green-600 text-white px-2 py-3"
                        >
                            <FaPen />
                        </button>
                    );
                },
            },
        )
    }
    if (isAdmin) {
        ALL_USERS_COLUMNS.push(
            {
                Header: "Delete Biomass Config",
                accessor: "deleteMaterial",
                Cell: ({ value, row }: any) => {
                    return (
                        <button
                            onClick={() => {
                                const biomass_composition_id = row?.original?.biomass_composition_id;
                                if (!biomass_composition_id) {
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "Error: Biomass Composition ID is missing.",
                                        })
                                    );
                                    return;
                                }
                                if (!window.confirm("Are you sure you want to delete this biomass composition?")) {
                                    return;
                                }
                                axiosInstance.delete(`/biomass_composition/${biomass_composition_id}`)
                                    .then((res) => {

                                        dispatch(
                                            addToast({
                                                kind: SUCCESS,
                                                msg: "Biomass Composition Deleted Successfully",
                                            })
                                        );
                                        queryClient.invalidateQueries("getAllBiomassComposition")
                                    }).catch((error: AxiosError) => {
                                        console.log(error.response);
                                        dispatch(
                                            addToast({
                                                kind: ERROR,
                                                msg: `Error: ${error.response?.data?.msg || "Failed to delete Biomass Composition"}`,
                                            })
                                        );
                                        return;
                                    })
                            }}
                            className="bg-green-600 text-white px-2 py-3"
                        >
                            <FaTrash />
                        </button>
                    );
                },

            })
    }

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getAllBiomassComposition = async ({ queryKey }: any) => {
        const { data } = await axiosInstance.get(`/biomass_composition/all`);
        return data?.data;
    };

    const { data, error, isLoading } = useQuery<
        BiomassObj[] | undefined,
        any,
        BiomassObj[],
        any[]
    >(
        ["getAllBiomassComposition", pageIndex, cPageSize, cSortBy, desc, q],
        getAllBiomassComposition
    );

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance excludeColumnSearch={[]}
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />

                    <span className="btn-primary flex items-center mt-5 w-48">
                        Selected Row : {selectedRows.length}
                    </span>
                </div>

                <div className="right">
                    <button
                        className="btn-primary flex items-center"
                        onClick={() => {
                            dispatch(
                                showModal({
                                    modalType: ADD_BIOMASS_COMPOSE_MODAL,
                                    modalTitle: "Add Biomass Config",
                                })
                            );
                        }}
                    >
                        <span className="mr-1">Add</span> <MdAdd size={17} />
                    </button>
                </div>
            </header>

            {component !== null && component}
        </div>
    );
};

export default BiomassComposition;
