import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR } from "../../types/constants";
import axiosInstance from "../../axiosInstance";

interface FarmFieldData {
    id: string;
    farmName: string;
    farmerName: string;
    coordinates: Coordinates;
    userId: string;
    createdAt: string;
    updatedAt: string;
    fields: Field[];
}

interface Field {
    id: string;
    fieldName: string;
    type: string;
    area: number;
    cropVariety: null;
    cropCycle: null;
    seedType: null;
    farmId: string;
    createdAt: string;
    updatedAt: string;
    soilTexture: null;
    cropId: null;
    age: null;
    treeCount: null;
    geofence_coordinates: null;
}

interface Coordinates {
    x: number;
    y: number;
}
interface DataObj {
    label: string;
    value: string;
    hidden?: boolean;
}
interface Props {
    index: number;
    title: string;
    data: DataObj[];
    address?: any;
    searchQuery: string;
}

function openInGoogleMaps(lat: string | number, lng: string | number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(url, "_blank");
}

function DetailedInfo({ index, title, data, address, searchQuery }: Props) {
    const dispatch = useDispatch();
    const [farmFieldData, setFarmFieldData] =
        React.useState<FarmFieldData | null>(null);
    const [farmFieldDataLoading, setFarmFieldDataLoading] = useState(false);
    const handleBuyerFarmclick = async () => {
        const buyerId = data.find((d) => d.label === "buyer_id")?.value;
        if (!buyerId) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: "General User ID not found",
                })
            );
        }
        setFarmFieldDataLoading(true);
        try {
            const res = await axiosInstance.post(
                "/admin/track/farm/" + buyerId
            );
            const data = res.data[res.data.length - 1];
            setFarmFieldData(data || {});
        } catch (error: any) {
            dispatch(
                addToast({
                    kind: ERROR,
                    msg: error.response.data.msg,
                })
            );
        }
        setFarmFieldDataLoading(false);
    };

    return (
        <div className="flex border border-green-400 shadow-lg bg-white">
            <div className="w-8 bg-green-500 text-center text-white pt-5 text-2xl h-full">
                {index}
            </div>
            <div className="w-full">
                <div className="flex items-center p-5">
                    <div className="w-2.5 h-2.5 bg-green-500 rounded-full mx-5"></div>
                    <h2 className="text-2xl font-bold">{title}</h2>
                    <div className="bg-green-500 p-2 rounded-full ml-auto">
                        {address?.coordinates != null ? (
                            <IoLocationSharp
                                size={18}
                                color="white"
                                onClick={() =>
                                    openInGoogleMaps(
                                        address?.coordinates?.x,
                                        address?.coordinates?.y
                                    )
                                }
                                className="cursor-pointer"
                            />
                        ) : (
                            <IoLocationSharp size={18} color="white" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col px-5">
                    <hr />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-6 my-8 justify-items-start items-start">
                        {data.map((data, index) => {
                            if (data?.hidden) return null;
                            return (
                                <div
                                    className="flex items-start gap-2"
                                    key={index}
                                >
                                    <h3 className="font-semibold text-base">
                                        {data?.label}:
                                    </h3>
                                    <p
                                        className={`text-base ${
                                            searchQuery === data?.value &&
                                            "bg-yellow-300"
                                        }`}
                                    >
                                        {data?.value}
                                    </p>
                                </div>
                            );
                        })}
                        {/* <div className="flex items-center gap-2">
                            <h3 className="font-semibold text-xl">Date:</h3>
                            <p className="text-lg">10/11/2024</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <h3 className="font-semibold text-xl">Contact:</h3>
                            <p className="text-lg">+91 996-XXX-XXXX</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <h3 className="font-semibold text-xl">Time:</h3>
                            <p className="text-lg">10:11:24</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <h3 className="font-semibold text-xl">FPO ID:</h3>
                            <p className="text-lg">XXXX-XXXX-XXXX-XXXX</p>
                        </div>
                        */}
                        {address != null && (
                            <div className="flex items-center col-span-2 gap-2">
                                <h3 className="font-semibold text-base">
                                    Location:
                                </h3>
                                <p className="text-base">
                                    {address?.address}, {address?.city},{" "}
                                    {address?.state}
                                </p>
                            </div>
                        )}
                        {title === "Buyers" && !farmFieldData && (
                            <button
                                onClick={handleBuyerFarmclick}
                                className="bg-green-600 text-white px-2 py-3"
                                disabled={farmFieldDataLoading}
                            >
                                View Farm Details
                            </button>
                        )}

                        {/* Farm Field Data */}
                        {farmFieldData &&
                            (farmFieldData?.fields?.length > 0 ? (
                                <div className="col-span-2">
                                    <h2 className="text-xl font-semibold mt-5">
                                        Farm Details
                                    </h2>
                                    <div
                                        className="flex items-start gap-2 mt-5"
                                        key={index}
                                    >
                                        <h3 className="font-semibold text-base">
                                            Farm Name:
                                        </h3>
                                        <p className="text-base">
                                            {farmFieldData?.farmName}
                                        </p>
                                    </div>
                                    <div
                                        className="flex items-start gap-2"
                                        key={index + 1}
                                    >
                                        <h3 className="font-semibold text-base">
                                            Farmer Name:
                                        </h3>
                                        <p className="text-base">
                                            {farmFieldData?.farmerName}
                                        </p>
                                    </div>
                                    <h2 className="my-5 font-semibold text-xl">
                                        Field Details
                                    </h2>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-2 justify-items-start items-start">
                                        {farmFieldData?.fields?.map(
                                            (field, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className="flex items-start gap-2"
                                                            key={index}
                                                        >
                                                            <h3 className="font-semibold text-base">
                                                                Field Name-Area
                                                            </h3>
                                                            <p className="text-base">
                                                                {
                                                                    field?.fieldName
                                                                }{" "}
                                                                <span className="font-bold">
                                                                    -
                                                                </span>
                                                                ({field?.area})
                                                            </p>
                                                        </div>
                                                        <br />
                                                    </>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className="font-bold">
                                        [No Farm Details Found]
                                    </p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailedInfo;
