import { Form, Formik } from "formik";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import Select from "../FormikComponents/Select";
import { VOLUME_UNIT } from "../../types/constants";

interface FormValues {
  vessel_name: string;
  manufacturer_name: string;
  vessel_type: string;
  volume_value: string;
  volume_unit: string;
}

const AddMesuringVesselModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [img, setImg] = useState<Object | null>(null);
  const initialValues: FormValues = {
    vessel_name: "",
    manufacturer_name: "",
    vessel_type: "",
    volume_value: "",
    volume_unit: VOLUME_UNIT[0].value,
  };

  const validationSchema = Yup.object({
    vessel_name: Yup.string().trim().required("Vessel Name is Required"),
    manufacturer_name: Yup.string().trim().required("Manufacturer Name is Required"),
    vessel_type: Yup.string().trim().required("Vessel Type is Required"),
    volume_value: Yup.string().trim().required("Volume is Required"),
    volume_unit: Yup.string().trim().required("Volume Unit is Required"),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!img) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Image is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const imgRes = await uploadImage(
        img,
        AZURE_STORAGE_DIRECTORY.MEASURING_VESSEL,
        AZURE_STORAGE_SUBDIRECTORY.PROFILE
      );
      if (imgRes.data.publicUrl) {
        const combinedVolume = `${values.volume_value} ${values.volume_unit}`;
        const obj = {
          vessel_name: values.vessel_name,
          manufacturer_name: values.manufacturer_name,
          vessel_type: values.vessel_type,
          volume: combinedVolume,
          vessel_image: imgRes.data.publicUrl,
        };

        const result = await axiosInstance.post("/measuring_vessel/create", obj);
        if (result) {

          setIsSubmitting(false);
          queryClient.invalidateQueries(["getAllMeasuringVessel"]);
          dispatch(
            addToast({
              kind: SUCCESS,
              msg: "Measuring Vessel Added Successfully!",
            })
          );
          dispatch(hideModal());
        }
      }
    } catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="px-6 py-4 mt-2" style={{ minWidth: "40vw" }}>
        <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Name"
            id="vessel_name"
            name="vessel_name"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Manufacturer Name"
            id="manufacturer_name"
            name="manufacturer_name"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Type"
            id="vessel_type"
            name="vessel_type"
            type="text"
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Volume"
            id="volume_value"
            name="volume_value"
            type="text"
          />
          <Select
            name="volume_unit"
            id="volume_unit"
            label="Unit"
            options={VOLUME_UNIT}
          />
        </div>

        <div className="flex item-center gap-x-6">
          <Input
            label="Upload Measuring Vessel Image"
            id="vessel_image"
            name="vessel_image"
            type="file"
            accept=".jpg,.png,.jpeg,.wbep"
            disabled={!!img}
            onChange={(e) => {

              if (e.target.files) {
                setImg(e.target.files[0]);
              }
            }}
          />
        </div>

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Add Measuring Vessel"
            isSubmitting={isSubmitting}
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>

      </Form>
    </Formik>
  );
};

export default AddMesuringVesselModal;
