import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FaPen, FaEye } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
    EDIT_USER_MODAL,
    ADD_NOTE_MODAL,
    ASSIGN_FPO_MODAL,
} from "../../types/constants";
import useAssignFPO from "../../hooks/useAssignFPO";
import useIsAdmin from "../../hooks/useIsAdmin";

interface UserAddress {
    label?: string;
    address?: string;
    city?: string;
    state?: string;
    pincode?: string;
    coordinates?: string;
    geofence_coordinates?: string;
    addressable_id?: string;
    addressable_type?: string;
    addressProofUrl?: string;
}

interface AllUsersDetailsObj {
    id: string;
    userId?: string;
    name: string;
    phonenumber?: string;
    companyName?: string;
    companyCategory?: string;
    type?: string;
    internal_fpo_user?: string;
    gstin?: string;
    fcoLicenseNumber?: string;
    msmeLicenseNumber?: string;
    kycValid?: string;
    productCategories?: string;
    addresses?: UserAddress[];
    verified?: boolean;
    createdAt: string;
    note?: string;
    fpo_id?: string;
    fpo_name?: string;
}

const AllUsersDetails = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { isAdmin } = useIsAdmin();
    const location = useLocation();
    const { id } = useParams();
    const { handleFPOAssign: originalHandleFPOAssign } = useAssignFPO();

    const handleApproveSeller = (sellerId: string) => {
        axiosInstance
            .post("/admin/seller/approve/", { sellerId })
            .then(() => {
                toast.success("Seller Approved Successfully!");
                queryClient.invalidateQueries("getSellers");
            });
    };

    const handleGetPrivateAddressProof = (url: string) => {
        if (!url) return;

        if (url.includes('old/addressproofuploads')) {
            axiosInstance
                .get(`/admin/addressproofs/${url.split('/').pop()}?directory=old/addressproofuploads`)
                .then((res) => {
                    window.open(res?.data?.data, "_blank");
                });
        } else if (url.includes('blob.core.windows.net')) {
            axiosInstance
                .get(`/admin/addressproofs/${url.split('/').pop()}`)
                .then((res) => {
                    window.open(res?.data?.data, "_blank");
                });
        } else {
            window.open(url);
        }
    };

    const handleFPOAssign = async (params: any) => {
        await originalHandleFPOAssign(params);
        queryClient.invalidateQueries("getSellers");
    };

    const formatDate = (dateString?: string) => {
        return dateString ? new Date(dateString).toLocaleString() : "Not Available";
    };

    const getSellers = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/seller/details/${id}`);
            // Check if data.data is an array, if not, wrap it in an array
            return Array.isArray(data.data) ? data.data : [data.data];
        } catch (error) {
            console.error("Error fetching users details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<AllUsersDetailsObj[]>(
        ["getSellers", id],
        getSellers
    );

    const userDataArray = Array.isArray(data) ? data : data ? [data] : [];

    return (
        <div className="p-6">
            {/* <h1 className="text-2xl font-bold mb-4">User Details</h1> */}
            {error && <p className="text-red-600">Error: {(error as Error).message}</p>}
            {isLoading ? (
                <p className="text-center">Loading...</p>
            ) : (
                <div className="bg-white shadow-md p-6 rounded-lg space-y-4 text-gray-800">
                    {userDataArray.map((user) => (
                        <div key={user.id} className="border-b pb-4">
                            <div className="grid grid-cols-1 gap-2">
                                {/* Basic Information */}
                                <div className="flex justify-between border-b pb-2">
                                    <strong>Seller ID:</strong>
                                    <span>{user.id}</span>
                                </div>
                                <div className="flex justify-between border-b pb-2">
                                    <strong>Name:</strong>
                                    <span>{user.name}</span>
                                </div>
                                <div className="flex justify-between border-b pb-2">
                                    <strong>Phone Number:</strong>
                                    <span>{user.phonenumber || "Not Available"}</span>
                                </div>
                                
                                {/* Company Information */}
                                <div className="flex justify-between border-b pb-2">
                                    <strong>Company Name:</strong>
                                    <span>{user.companyName || "Not Available"}</span>
                                </div>
                                <div className="flex justify-between border-b pb-2">
                                    <strong>Company Category:</strong>
                                    <span>{user.companyCategory || "Not Available"}</span>
                                </div>
                                
                                {/* Business Documents */}
                                <div className="flex justify-between border-b pb-2">
                                    <strong>GSTIN:</strong>
                                    <span>{user.gstin || "Not Available"}</span>
                                </div>
                                <div className="flex justify-between border-b pb-2">
                                    <strong>FCO License:</strong>
                                    <span>{user.fcoLicenseNumber || "Not Available"}</span>
                                </div>

                                {/* Address Information */}
                                {user.addresses?.[0] && (
                                    <>
                                        <div className="flex justify-between border-b pb-2">
                                            <strong>Address:</strong>
                                            <span>{user.addresses[0].address || "Not Available"}</span>
                                        </div>
                                        <div className="flex justify-between border-b pb-2">
                                            <strong>City:</strong>
                                            <span>{user.addresses[0].city || "Not Available"}</span>
                                        </div>
                                        <div className="flex justify-between border-b pb-2">
                                            <strong>State:</strong>
                                            <span>{user.addresses[0].state || "Not Available"}</span>
                                        </div>
                                    </>
                                )}

                                <div className="flex justify-between border-b pb-2">
                                    <strong>Created At:</strong>
                                    <span>{formatDate(user.createdAt)}</span>
                                </div>

                                {/* Action Buttons */}
                                <div className="flex space-x-3 mt-4">
                                    {!user.verified && (
                                        <button
                                            onClick={() => handleApproveSeller(user.id)}
                                            className="bg-green-600 text-white px-4 py-2 rounded"
                                        >
                                            Approve Seller
                                        </button>
                                    )}
                                    
                                    <button
                                        onClick={() =>
                                            dispatch(
                                                showModal({
                                                    modalType: EDIT_USER_MODAL,
                                                    modalTitle: "Edit Seller",
                                                    modalProps: { row: user },
                                                })
                                            )
                                        }
                                        className="bg-blue-600 text-white p-2 rounded"
                                    >
                                        <FaPen />
                                    </button>

                                    <button
                                        onClick={() =>
                                            dispatch(
                                                showModal({
                                                    modalType: ADD_NOTE_MODAL,
                                                    modalTitle: "Add/View Notes",
                                                    modalProps: {
                                                        sellerId: user.id,
                                                        name: user.name,
                                                        notes: user.note,
                                                    },
                                                })
                                            )
                                        }
                                        className="bg-green-600 text-white p-2 rounded"
                                    >
                                        <FaEye />
                                    </button>

                                    {user.addresses?.[0]?.addressProofUrl && (
                                        <button
                                            onClick={() => handleGetPrivateAddressProof(user.addresses![0].addressProofUrl!)}
                                            className="bg-green-600 text-white p-2 rounded"
                                        >
                                            <FaEye />
                                        </button>
                                    )}

                                    {isAdmin && (
                                        <button
                                            onClick={() => {
                                                if (user.fpo_id) {
                                                    handleFPOAssign({
                                                        id: user.userId,
                                                        action: "unassign",
                                                        user_role: "seller",
                                                        fpo_id: user.fpo_id,
                                                    });
                                                } else {
                                                    dispatch(
                                                        showModal({
                                                            modalType: ASSIGN_FPO_MODAL,
                                                            modalTitle: "Assign FPO",
                                                            modalProps: {
                                                                id: user.userId,
                                                                action: "assign",
                                                                role: "seller",
                                                                onSuccess: () => {
                                                                    queryClient.invalidateQueries("getSellers");
                                                                },
                                                            },
                                                        })
                                                    );
                                                }
                                            }}
                                            className={`${user.fpo_id ? "bg-red-500" : "bg-green-600"} text-white px-4 py-2 rounded`}
                                        >
                                            {location.pathname.includes("/fpo/details")
                                                ? "Unlink Seller"
                                                : user.fpo_id
                                                ? "Unassign FPO"
                                                : "Assign FPO"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AllUsersDetails;