import React, { useState } from "react";

interface ImagePreviewProps {
  imageUrl: string | null;
  altText: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ imageUrl, altText }) => {
  const [isImageError, setIsImageError] = useState(false);

  if (!imageUrl) {
    return <div className="mt-2 text-center text-gray-500">No Image Found</div>;
  }

  return (
    <div className="mt-2 text-center">
      {isImageError ? (
        <div className="text-red-500 font-semibold">Unable to show</div>
      ) : (
        <a href={imageUrl} target="_blank" rel="noopener noreferrer">
          <img
            src={imageUrl}
            alt={altText}
            onError={() => setIsImageError(true)}
            // style={{
            //   width: "100%",
            //   height: "auto",
            //   objectFit: "cover",
            //   borderRadius: "7px",
            // }}
            style={{
              width: "700px", // Fixed width for the image
              height: "600px", // Fixed height for the image
              // objectFit: "cover",
              objectFit: "contain", // Changed objectFit to contain
              borderRadius: "7px",
            }}
          />
        </a>
      )}
    </div>
  );
};

export default ImagePreview;
