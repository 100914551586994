import { Form, Formik } from "formik";
import { useState } from "react";
import { MdCancel } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { uploadImage } from "../../http/uploadImage";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import {
  AZURE_STORAGE_DIRECTORY,
  AZURE_STORAGE_SUBDIRECTORY,
  ERROR, SUCCESS,
  LENGTH_UNIT
} from "../../types/constants";
import AddNewFields from "../AddNewFields";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface FormValues {
  biomass_name: string;
  cotton_stock: string;
  rice_straw: string;
  average_biomass_diameter: number;
  average_biomass_length: number;
}

const AddBiomassComposeModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [new_fields, setNewFields] = useState<
    { title: string; value: number }[]
  >([]);

  const [isNewField, setIsNewField] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [img, setImg] = useState<Object | null>(null);
  const [diameterUnit, setDiameterUnit] = useState("cm");
  const [lengthUnit, setLengthUnit] = useState("cm");

  const initialValues: FormValues = {
    biomass_name: "",
    cotton_stock: "",
    rice_straw: "",
    average_biomass_diameter: 0,
    average_biomass_length: 0,
  };

  const validationSchema = Yup.object({
    biomass_name: Yup.string()
      .trim()
      .required("Biomass Name is Required"),
    cotton_stock: Yup.string()
      .trim()
      .required("Cotton Stock is Required"),
    rice_straw: Yup.string()
      .trim()
      .required("Rice Straw is Required"),
    average_biomass_diameter: Yup.number()
      .min(0.002, "Average Biomass Diameter must be at least 0.002")
      .required("Average Biomass Diameter is Required"),
    average_biomass_length: Yup.number()
      .min(0.002, "Average Biomass Length must be at least 0.002")
      .required("Average Biomass Length is Required"),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleSubmit = async (biomass_compose: FormValues) => {
    if (!img) {
      dispatch(
        addToast({
          kind: ERROR,
          msg: "Image is required",
        })
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const imgRes = await uploadImage(
        img,
        AZURE_STORAGE_DIRECTORY.BIOMASS_COMPOSITION,
        AZURE_STORAGE_SUBDIRECTORY.PROFILE
      );

      if (!imgRes.data.publicUrl) {
        throw new Error("Image upload failed");
      }

      const compostObj = new_fields.reduce((acc: any, item) => {
        acc[item.title] = item.value;
        return acc;
      }, {});

      const obj = {
        biomass_name: biomass_compose.biomass_name,
        biomass_blend: {
          cotton_stock: biomass_compose.cotton_stock,
          rice_straw: biomass_compose.rice_straw,
          ...compostObj,
        },
        average_biomass_diameter: biomass_compose.average_biomass_diameter,
        average_biomass_length: biomass_compose.average_biomass_length,
        biomass_photo: imgRes.data.publicUrl,
      };

      const result = await axiosInstance.post(
        "/biomass_composition/create",
        obj
      );

      if (result) {
        setIsSubmitting(false);
        queryClient.invalidateQueries(["getAllBiomassComposition"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Biomass Config Added Successfully!",
          })
        );
        dispatch(hideModal());
      }
    }
    catch (error: any) {
      setIsSubmitting(false);

      if (error.response) {
        const response = error.response;
        const { msg } = response.data;

        switch (response.status) {
          case 400:
          case 500:
            dispatch(
              addToast({
                kind: ERROR,
                msg: msg,
              })
            );
            break;
          default:
            dispatch(
              addToast({
                kind: ERROR,
                msg: "Oops, something went wrong",
              })
            );
            break;
        }
      } else if (error.request) {
        dispatch(
          addToast({
            kind: ERROR,
            msg: "Oops, something went wrong",
          })
        );
      } else {
        dispatch(
          addToast({
            kind: ERROR,
            msg: `Error: ${error.message}`,
          })
        );
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form
          className="px-6 py-4 mt-2"
          // style={{
          //   minWidth: "360px",
          //   maxWidth: "760px",
          // }}
          style={{ minWidth: "40vw" }}
        >
          <div className="flex item-center gap-x-6">
            <Input
              label="Biomass Name"
              id="biomass_name"
              name="biomass_name"
              type="text"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Cotton Stock"
              id="cotton_stock"
              name="cotton_stock"
              type="number"
            />
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Rice Straw"
              id="rice_straw"
              name="rice_straw"
              type="number"
            />
          </div>

          {new_fields.map((field, index) => (
            <div key={index} className="flex item-center gap-x-6 relative">
              <MdCancel
                size={20}
                className="absolute right-0 text-primary cursor-pointer"
                onClick={() => {
                  setNewFields(
                    new_fields.filter((item) => item.title !== field.title)
                  );
                }}
              />

              <Input
                label={field.title}
                id={field.title}
                name={field.title}
                type="text"
                value={field.value}
                readOnly
              />
            </div>
          ))}
          <div className="flex justify-center items-center mb-4">
            {!isNewField ? (
              <button
                onClick={() => {
                  setIsNewField(true);
                }}
                className="my-2 border-2 text-white bg-primary p-1 w-full"
              >
                Add New Field{" "}
              </button>
            ) : (
              <AddNewFields
                handleAdd={(title, value) => {
                  const fieldExists = new_fields.find(
                    (field) => field.title.toLowerCase() === title.toLowerCase()
                  );
                  if (fieldExists) {
                    dispatch(
                      addToast({
                        kind: ERROR,
                        msg: "Field already exists",
                      })
                    );
                    return;
                  }

                  setNewFields([...new_fields, { title, value: +value }]);
                  setIsNewField(false);
                }}
                handleCancel={() => {
                  setIsNewField(false);
                }}
              />
            )}
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Average Biomass Diameter (${diameterUnit})`}
                id="average_biomass_diameter"
                name="average_biomass_diameter"
                type="number"
              />
            </div>
            <div>
              <select
                value={diameterUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(diameterUnit, newUnit);
                  setFieldValue("average_biomass_diameter", values.average_biomass_diameter * conversionFactor);
                  setDiameterUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Average Biomass Length (${lengthUnit})`}
                id="average_biomass_length"
                name="average_biomass_length"
                type="number"
              />
            </div>
            <div>
              <select
                value={lengthUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  const conversionFactor = getConversionFactor(lengthUnit, newUnit);
                  setFieldValue("average_biomass_length", values.average_biomass_length * conversionFactor);
                  setLengthUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex item-center gap-x-6">
            <Input
              label="Biomass Photo"
              id="biomass_photo"
              name="biomass_photo"
              type="file"
              accept=".jpg,.png,.jpeg,.wbep"
              disabled={!!img}
              onChange={(e) => {
                if (e.target.files) {
                  setImg(e.target.files[0]);
                }
              }}
            />
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              // classes="text-sm"
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddBiomassComposeModal;
