import { useState } from "react";
import { useQuery } from "react-query";
import { FaEye } from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { useParams } from "react-router-dom";

interface SequestrationDetailsObj {
    biomass_batch_id: string;
    batch_date: string;
    compost_details: {
        "Select Compost Type": string;
        total_compost_qty: number;
    };
    incorporation_time: string;
    mixing_image: string;
    final_image: string;
    created_at: string;
    updated_at: string;
    sequestration_type: {
        main_type: string;
    };
    biochar_weight: number;
    sp_id: string | null;
    sequestration_id: string;
}

interface SequestrationDetailsProps {
    otherUrl?: string;
}

const SequestrationDetails = ({ otherUrl }: SequestrationDetailsProps) => {
    const { biomass_batch_id, sequestration_id } = useParams();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber",
            Cell: ({ row }: any) => row.index + 1,
        },
        {
            Header: "Biomass Batch ID",
            accessor: "biomass_batch_id",
            Cell: ({ value }: any) => value ? value : 'Not Available',
        },
        {
            Header: "Batch Date",
            accessor: "batch_date",
            Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
        },
        {
            Header: "Compost Qty",
            accessor: "compost_details",
            Cell: ({ value }: { value: any }) => {
                if (!value) return 'Not Available';
                const formatKey = (key: string): string =>
                    key.replace(/_/g, ' ')
                        .replace(/\b\w/g, (char: string) => char.toUpperCase());
                const displayText = Object.entries(value)
                    .filter(([key, val]) => val)
                    .map(([key, val]) =>
                        `<div style="display: flex; justify-content: space-between; margin: 4px 0; padding: 2px 4px;">
                            <span style="justify-self: start;">${formatKey(key)}:</span>
                            <span style="justify-self: end;">${val},</span>
                        </div>`
                    )
                    .join("");
                return <div style={{ padding: '8px' }} dangerouslySetInnerHTML={{ __html: displayText }} />;
            }
        },
        {
            Header: "Incorporation Time",
            accessor: "incorporation_time",
            Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
        },
        {
            Header: "Biochar Weight",
            accessor: "biochar_weight",
            Cell: ({ value }: any) => value ? `${value} Kg` : 'Not Available',
        },
        {
            Header: "SP ID",
            accessor: "sp_id",
            Cell: ({ value }: any) => value ? value : 'Not Available',
        },
        {
            Header: "Sequestration ID",
            accessor: "sequestration_id",
            Cell: ({ value }: any) => value ? value : 'Not Available',
        },
        {
            Header: "Sequestration Type",
            accessor: "sequestration_type.main_type",
            Cell: ({ value }: any) => {
                if (!value) return 'Not Available';
                const formattedValue: string = value
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char: string) => char.toUpperCase());
                return formattedValue;
            },
        },
        {
            Header: "Mixing Image",
            accessor: "mixing_image",
            Cell: ({ value }: any) => {
                return value ? (
                    <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
                        <button className="bg-green-600 text-white px-2 py-3">
                            <FaEye />
                        </button>
                    </a>
                ) : (
                    <span>Not Available</span>
                );
            },
        },
        {
            Header: "Final Image",
            accessor: "final_image",
            Cell: ({ value }: any) => {
                return value ? (
                    <a href={value} className="text-green-400" target="_blank" rel="noreferrer">
                        <button className="bg-green-600 text-white px-2 py-3">
                            <FaEye />
                        </button>
                    </a>
                ) : (
                    <span>Not Available</span>
                );
            },
        },
        {
            Header: "Created At",
            accessor: "created_at",
            Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
        },
        {
            Header: "Updated At",
            accessor: "updated_at",
            Cell: ({ value }: any) => value ? new Date(value).toLocaleString() : 'Not Available',
        },
    ];

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [q, setQ] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    // Query for the single sequestration based on the provided IDs
    const getSingleSequestration = async () => {
        const { data } = await axiosInstance.get(`biomass_batch/sequestration/${biomass_batch_id}/${sequestration_id}`);
        return data?.data;
    };

    const { data, error, isLoading } = useQuery<SequestrationDetailsObj | undefined, Error>(
        ["getSingleSequestration", biomass_batch_id, sequestration_id],
        getSingleSequestration
    );

    let component = null;

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                excludeColumnSearch={[]}
                tableData={data ? [data] : []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={false}
                cSortBy={[]}
                cSetSortBy={() => { }}
                desc={false}
                setDesc={() => { }}
            />
        );
    }

    return (
        <div className="all-users">
            <header className="flex items-center justify-between mb-8">
                <div className="left">
                    <SearchBox
                        searchValue={q}
                        setSearchValue={setQ}
                        handleSearch={() => { }}
                    />
                    <span className="btn-primary flex items-center mt-5 w-48">
                        Selected Rows: {selectedRows.length}
                    </span>
                </div>
            </header>
            {component !== null && component}
        </div>
    );
};

export default SequestrationDetails;
