import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface CategoriesObj {
  category: string;
  is_biomass: boolean; // Add is_biomass field
}

type EditCategoriesModalProps = {
  categoryId: string;
  category: string;
  is_biomass: boolean; // Add is_biomass prop
};

const EditAllCategoriesModal: React.FC<EditCategoriesModalProps> = ({
  categoryId,
  category,
  is_biomass,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: CategoriesObj = {
    category: category,
    is_biomass: is_biomass, // Initialize with is_biomass value
  };

  const validationSchema = Yup.object({
    category: Yup.string().trim().required("Category Title is Required"),
    // is_biomass: Yup.boolean().required("Biomass status is required"), // Validate is_biomass
    is_biomass: Yup.boolean().optional(), // Validate is_biomass
  });

  const handleSubmit = useCallback(
    async (categories: CategoriesObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/admin/productcategories/${categoryId}`,
          {
            category: categories.category,
            is_biomass: categories.is_biomass, // Send is_biomass in request
          },
          { headers: { ContentType: "application/json" } }
        );

        queryClient.invalidateQueries(["getCategories"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Categories Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [categoryId, dispatch, queryClient]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{ minWidth: "40vw" }}
        >
          <div className="flex item-center gap-x-6">
            <Input label="Category Title" id="category" name="category" type="text" />
          </div>

          <div className="flex item-center gap-x-6 mt-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="is_biomass"
                id="is_biomass"
                checked={initialValues.is_biomass} // Toggle checkbox based on is_biomass value
                onChange={(e) => {
                  setFieldValue("is_biomass", e.target.checked);
                }}
              />
              <span>Mark as BioMass Category</span>
            </label>
          </div>

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditAllCategoriesModal;
