import { AxiosError } from "axios";
import { Form, Formik, } from "formik";
import React, { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, LAND_HOLDING_UNIT, SUCCESS } from "../../types/constants";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import Select from "../FormikComponents/Select";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import CenterMarker from "../MapsComponents/CenterMarker";

const stateArray = [
    { value: "", label: "Select State" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
];

interface UserObj {
    name: string;
    phonenumber: string;
    email: string;
    address: string;
    city: string;
    state: string;
    lat?: string;
    lng?: string;
    pincode: string;
    land_holding?: number | string;
    land_holding_unit?: string;
    fpo_id?: string;


}

const AddBuyerModal: React.FC = () => {
    const [role, setRole] = useState(localStorage.getItem('role'))
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [commonError, setCommonError] = useState("");
    const [showForm, setShowForm] = useState(true);
    const queryClient = useQueryClient();
    const initialValues: UserObj = {
        name: "",
        phonenumber: "",
        email: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        lat: "0",
        lng: "0",
        land_holding: 0,
        land_holding_unit: "hectare",
        fpo_id: ""
    };

    const validationSchema = Yup.object({
        name: Yup.string().trim().required("Name is required"),
        phonenumber: Yup.string()
            .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
            .length(10, "Phone Number must be exactly 10 digits")
            .required("Phone Number is required"),
        email: Yup.string().trim()
            .email("Invalid email address")
            .required("Email is required"),
        address: Yup.string().trim().required("Address is required"),
        city: Yup.string().trim().required("City is required"),
        state: Yup.string().trim().required("State is required"),
        pincode: Yup.string()
            .matches(/^[0-9]+$/, "Invalid. Pin Code must be only digits")
            .length(6, "Pin Code must be exactly 6 digits")
            .required("Pin Code is required"),
        lat: Yup.string().nullable(),
        lng: Yup.string().nullable(),
        fpo_id: Yup.string()
            .min(12, "FPO ID must be minimum of length 12")
            .optional(),
        land_holding: Yup.number().optional().min(0, "Land Holding must be greater than 0"),
        land_holding_unit: Yup.string().trim().optional(),
    });

    const handleSubmit = useCallback(
        (
            values: UserObj,
            { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
        ) => {
            setIsSubmitting(true);
            setCommonError("");

            try {
                const obj: UserObj = {
                    name: values.name,
                    phonenumber: values.phonenumber.toString(),
                    email: values.email,
                    address: values.address,
                    city: values.city,
                    state: values.state,
                    pincode: values.pincode.toString(),

                };
                if (values.lat !== "" && values.lng !== "") {
                    obj.lat = values.lat;
                    obj.lng = values.lng;
                }
                if (values.land_holding !== 0 || values.land_holding !== undefined) {
                    if (values.land_holding_unit === "") {
                        setCommonError("Please select a unit for land holding");
                        setIsSubmitting(false);
                        setSubmitting(false);
                        return;
                    }
                    let landHoldingInHectares = values.land_holding as number;
                    switch (values.land_holding_unit) {
                        case "acre":
                            landHoldingInHectares *= 0.404686;
                            break;
                        case "sqft":
                            landHoldingInHectares *= 0.0000092903;
                            break;
                        case "sqm":
                            landHoldingInHectares *= 0.0001;
                            break;
                        default:
                            break;
                    }
                    obj.land_holding = landHoldingInHectares;
                    // obj.land_holding_unit = values.land_holding_unit;
                }
                if (values.fpo_id !== "") {
                    obj.fpo_id = values.fpo_id
                }

                axiosInstance
                    .post(`/admin/users/create`, obj, {
                        headers: {
                            ContentType: "application/json",
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            setIsSubmitting(false);
                            setSubmitting(false);
                            dispatch(
                                addToast({
                                    kind: SUCCESS,
                                    msg: "General User Added Successfully!",
                                })
                            );
                            queryClient.invalidateQueries(["getAllUsers"]);
                            setShowForm(false);
                            dispatch(hideModal());
                        }
                    })
                    .catch((error: AxiosError) => {
                        setIsSubmitting(false);
                        setSubmitting(false);

                        if (error.response) {
                            const { msg } = error.response.data as { msg: string };
                            switch (error.response.status) {
                                case 400:
                                case 403:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: `Error: ${msg}`,
                                        })
                                    );
                                    break;
                                case 500:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: `Something went wrong / Invalid FPO`,
                                        })
                                    );
                                    break;
                                case 404:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "No data found for the given General User.",
                                        })
                                    );
                                    break;
                                default:
                                    dispatch(
                                        addToast({
                                            kind: ERROR,
                                            msg: "Oops, something went wrong",
                                        })
                                    );
                                    break;
                            }
                        } else if (error.request) {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: "Oops, something went wrong",
                                })
                            )
                        } else {
                            dispatch(
                                addToast({
                                    kind: ERROR,
                                    msg: `Error: ${error.message}`
                                })
                            )
                        }
                    });
            }
            catch (error) {
                setCommonError("Invalid Role!!");
                setIsSubmitting(false);
                setSubmitting(false);
                return;
            }

        },
        [dispatch, queryClient]
    );


    return (
        <>
            {showForm && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        setFieldTouched,
                        values,
                        errors,
                        touched,
                    }) => (
                        <Form
                            className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
                            style={{ minWidth: "40vw" }}
                        >
                            <img src={logo} alt="logo" height={100} width={100} />

                            <Input
                                label="Name"
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Enter Name"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Phone Number"
                                id="phonenumber"
                                name="phonenumber"
                                type="number"
                                placeholder="91 xxxxx xxxxx"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />
                            <Input
                                label="Email"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="example@gmail.com"
                                className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                            />

                            <div className="mb-0 w-full">

                                <Input
                                    label="Address"
                                    id="address"
                                    name="address"
                                    type="text"
                                    placeholder="Address"
                                    className="block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />

                                <div className="flex mb-4 w-full space-x-4">
                                    <Input
                                        label="City"
                                        id="city"
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                    <div className="w-full">
                                        <Select
                                            options={stateArray}
                                            label="State"
                                            id="state"
                                            name="state"
                                        />
                                    </div>
                                    <Input
                                        label="Pin Code"
                                        id="pincode"
                                        name="pincode"
                                        type="number"
                                        placeholder="Pin Code"
                                        className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    />
                                </div>
                                <Input
                                    label="Latitude"
                                    id="lat"
                                    name="lat"
                                    type="text"
                                    placeholder="Latitude"
                                    readOnly
                                    className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                                <Input
                                    label="Longitude"
                                    id="lng"
                                    name="lng"
                                    type="text"
                                    placeholder="Longitude"
                                    readOnly
                                    className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                            </div>

                            <div className="w-full h-96 rounded-xl mb-5">

                                <CenterMarker latName="lat" lngName="lng" />

                            </div>

                            <div className="grid grid-cols-3 gap-x-4 w-full">
                                <Input
                                    label="Land Holding"
                                    id="land_holding"
                                    name="land_holding"
                                    type="number"
                                    placeholder="Land Holding"
                                    className="block flex-1 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                    classes="col-span-2"
                                />
                                <Select
                                    name="land_holding_unit"
                                    id="land_holding_unit"
                                    label="Unit"
                                    options={LAND_HOLDING_UNIT}
                                />
                            </div>

                            {role === 'admin' && (
                                <Input
                                    label="FPO ID"
                                    id="fpo_id"
                                    name="fpo_id"
                                    type="text"
                                    placeholder="SUN-FPO-XXXXXXXXXX"
                                    className=" block w-full rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                                />
                            )}

                            {commonError && (
                                <div className="common-error mb-2 text-center">
                                    <ErrorBox msg={commonError} />
                                </div>
                            )}

                            <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
                                <SubmitBtn
                                    text="Add Buyer"
                                    isSubmitting={isSubmitting}
                                    // classes="text-sm"
                                    containerClasses="h-full"
                                    isSubmittingClasses="lg:mt-6"
                                    classes="text-sm lg:p-4 lg:rounded-lg"
                                />
                            </div>
                        </Form>)}
                </Formik>
            )}
        </>
    );
};

export default AddBuyerModal;
