const getPageTitle = (pathname: string): string => {
  let title = "";

  switch (pathname) {
    case "/":
      title = "Dashboard";
      break;

    case "/users":
    case "/users/accounts":
    case "/users/sales":
    case "/users/purchases":
    case "/users/market":
    case "/users/buyers":
    case "/users/fpo":
    case "/users/artisans":
    case "/users/artisans/approved":
      title = "Users";
      break;

    case "/seller":
      title = "Seller";
      break;

    case "/buyer":
      title = "Buyer";
      break;

    case "/artisan":
    case "/artisan/approved":
      title = "Artisan";
      break;

    case "/users/fpo/details/:id":
      title = "FPO Details";
      break;

    case "/sequestration":
      title = "Sequestration";
      break;

    case "/products":
    case "/products/deleted":
      title = "Products";
      break;

    case "/categories":
      title = "Product Categories";
      break;

    case "/product-formulation":
    case "/product-formulation/transfer":
      title = "Product Formulation";
      break;

    case "/track-and-trace":
      title = "Track and Trace";
      break;

    case "/crops":
      title = "Crops";
      break;

    case "/fertilisers":
    case "/fertiliser/comb":
      title = "Fertiliser Combination";
      break;

    case "/biomass-batch":
      title = "BioChar";
      break;

    case "/biomass-composition":
      title = "Biomass Composition";
      break;

    case "/pyrolysis-kiln":
      title = "Pyrolysis Kiln";
      break;

    case "/soil-pit":
      title = "Soil Pit";
      break;

    case "/measuring-vessel":
      title = "Measuring Vessel";
      break;

    case "/project":
      title = "Project";
      break;

    case "/inventory":
      title = "Inventory";
      break;

    case "/accounts":
    case "/accounts/debtors":
    case "/accounts/creditors":
    case "/accounts/orders/pending-orders":
    case "/accounts/orders/approved-orders":
    case "/accounts/orders/declined-orders":
      title = "Accounts";
      break;

    case "/sales":
    case "/sales/created":
    case "/sales/challaans":
    case "/sales/invoices":
      title = "Sales";
      break;

    case "/purchases":
      title = "Purchase";
      break;

    case "/hr":
      title = "HR";
      break;

    case "/customers":
      title = "Customer";
      break;

    case "/configuration":
    case "/configuration/head":
    case "/configuration/company":
    case "/configuration/bank-account":
    case "/configuration/transport":
      title = "Configuration";
      break;

    case "/discount":
      title = "Discount";
      break;

    case "/supplier":
      title = "Supplier";
      break;

    case "/fpo-inventory":
    case "/fpo-inventory/transactions":
      title = "FPO Inventory";
      break;

    case "/compost-material":
      title = "Compost Material";
      break;

    default:
      title = "";
  }

  if (/^\/seller\/details\/SUN-SELL-\d+$/.test(pathname)) {
    title = "Seller Details";
  }

  if (/^\/buyer\/details\/[A-Za-z0-9-]+$/.test(pathname)) {
    title = "Buyer Details";
  }

  if (
    /^\/artisans\/[A-Za-z0-9-]+$/.test(pathname) &&
    !/\/approved$/.test(pathname)
  ) {
    title = "Artisan Pending Details";
  }

  if (/^\/artisan\/approved\/[A-Za-z0-9-]+$/.test(pathname)) {
    title = "Artisan Approved Details";
  }

  if (/^\/sequestration\/[A-Za-z0-9-]+\/SUN-SELL-\+$/.test(pathname)) {
    title = "Sequestration Details";
  }

  if (/^\/sequestration"\/[A-Za-z0-9-]+$/.test(pathname)) {
    title = "Sequestration Details";
  }

  // if (/^\/compost-material\/[0-9]+$/.test(pathname)) {
  //   title = "Compost Material Details";
  // }

  if (/^\/users\/details\/SUN-SELL-\d+$/.test(pathname)) {
    title = "Seller Details";
  }

  if (/^\/users\/buyers\/details\/.+/.test(pathname)) {
    title = "General User Details";
  }

  if (/^\/users\/artisans\/approved\/.+/.test(pathname)) {
    title = "Artisan Approved Details";
  }

  if (
    /^\/users\/artisans\/[A-Za-z0-9-]+$/.test(pathname) &&
    !/\/approved$/.test(pathname)
  ) {
    title = "Artisan Pending Details";
  }

  if (/^\/products\/details\/.+/.test(pathname)) {
    title = "Product Details";
  }

  // if (
  //   /^\/product-formulation\/[A-Za-z0-9-]+$/.test(pathname) &&
  //   !/\/transfer$/.test(pathname)
  // ) {
  //   title = "All Product Formulation Details";
  // }

  // if (/^\/product-formulation\/transfer\/[0-9-]+/.test(pathname)) {
  //   title = "All Product Transfer Details";
  // }

  // if (/^\/fertiliser\/comb\/.+/.test(pathname)) {
  //   title = "All Fertilisers Combinations Details";
  // }

  // if (/^\/crops\/.+/.test(pathname)) {
  //   title = "Crop Details";
  // }

  if (/^\/biomass-batch\/.+/.test(pathname)) {
    title = "BioChar Details";
  }

  // if (/^\/categories\/.+/.test(pathname)) {
  //   title = "Product Categories Details";
  // }

  // if (/^\/fertilisers\/.+/.test(pathname)) {
  //   title = "All Fertilisers Details";
  // }

  // if (/^\/biomass-composition\/.+/.test(pathname)) {
  //   title = "Biomass Composition Details";
  // }

  // if (/^\/pyrolysis-kiln\/.+/.test(pathname)) {
  //   title = "Pyrolysis Kiln Details";
  // }

  // if (/^\/soil-pit\/.+/.test(pathname)) {
  //   title = "Soil Pit Details";
  // }

  // if (/^\/measuring-vessel\/.+/.test(pathname)) {
  //   title = "Measuring Vessel Details";
  // }

  // if (/^\/project\/.+/.test(pathname)) {
  //   title = "Project Details";
  // }

  if (/\/users\/fpo\/details\/.+/.test(pathname)) {
    title = "FPO Details";
  }
  return title;
};

export default getPageTitle;
