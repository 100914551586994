import { useState } from "react";
import { useQuery } from "react-query";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { NavLink } from "react-router-dom";

interface UserObj {
  id: string;
  name: string;
  createdAt: string;
}

const AllProducts = () => {
  // const handleApproveProduct = (id: string) => {
  //   axiosInstance.get(`/admin/products/${id}/approve`).then((res: any) => {

  //     toast.success("Product Approved Successfully!");
  //     queryClient.invalidateQueries("getProducts");
  //   });
  // };

  // const handleSoftDeleteProduct = (id: string) => {
  //   axiosInstance.delete(`/products/${id}`).then((res: any) => {

  //     toast.success("Product Deleted Successfully!");
  //     queryClient.invalidateQueries("getProducts");
  //   });
  // };

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    // {
    //   Header: "Product Id",
    //   accessor: "id",
    //   Cell: ({ row }: any) => {
    //     const id = row?.original.id;
    //     return (
    //       <NavLink
    //         to={`${row?.original.id}`}
    //         className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
    //         title={`View Products Details`}
    //       >
    //         {id}
    //       </NavLink>
    //     );
    //   },
    // },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }: any) => {
        const name = row?.original.name || "Not Available";
        const id = row?.original.id || "#";
        return (
          <NavLink
            to={`details/${id}`}
            className="text-green px-2 py-3 transition-all duration-200 ease-in-out hover:underline"
            title="View Products Details"
          >
            {name}
          </NavLink>
        );
      },
    },
    // {
    //   Header: "Price",
    //   accessor: "actualPrice",
    //   Cell: ({ value, row }: any) => {
    //     console.table(row.original);
    //     return (
    //       <>
    //         {value === row.original.discountedPrice ? (
    //           <p>{row.original.discountedPrice}</p>
    //         ) : (
    //           <p>
    //             <del>{value}</del> {row.original.discountedPrice}
    //           </p>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   Header: "Stock ( KG )",
    //   accessor: "stockInKG",
    // },
    {
      Header: "City",
      accessor: "city",
      Cell: ({ value }: any) => {
        return value ? value : "Not Available";
      },
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }: any) => {
        return value ? new Date(value).toLocaleString() : "Invalid Date";
      },
    },
    // {
    //   Header: "Address Proof",
    //   accessor: "addressProofUrl",
    //   Cell: ({ value }: any) => {
    //     return value ? (
    //       <a
    //         href={value}
    //         className="text-green-400"
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         <button className="bg-green-600 text-white px-2 py-3">
    //           <FaEye />
    //         </button>
    //       </a>
    //     ) : (
    //       <p className="text-red-500">No image</p>
    //     );
    //   },
    // },
    // {
    //   Header: "Verified?",
    //   accessor: "verified",
    //   Cell: ({ value, row }: any) => {
    //     return value ? (
    //       "Yes"
    //     ) : (
    //       <button
    //         onClick={() => handleApproveProduct(row.original.productId)}
    //         className="bg-green-600 text-white px-2 py-3"
    //       >
    //         Approve Product
    //       </button>
    //     );
    //   },
    // },
    // {
    //   Header: "Edit",
    //   accessor: "edit",
    //   Cell: ({ value, row }: any) => {
    //     console.table(row.original);
    //     return (
    //       <button
    //         onClick={() => {
    //           dispatch(
    //             showModal({
    //               modalType: EDIT_PRODUCT_MODAL,
    //               modalTitle: "Edit a Product",
    //               modalProps: {
    //                 productId: row?.original.productId,
    //                 name: row?.original.name,
    //                 actualPrice: row?.original.actualPrice,
    //                 discountedPrice: row?.original.discountedPrice,
    //                 stock: row?.original.stockInKG,
    //                 addressId: row?.original.addressId,
    //               },
    //             })
    //           );
    //         }}
    //         className="bg-green-600 text-white px-2 py-3"
    //       >
    //         <FaPen />
    //       </button>
    //     );
    //   },
    // },
    // {
    //   Header: "Delete",
    //   accessor: "updatedAt",
    //   Cell: ({ value, row }: any) => {
    //     return (
    //       <button
    //         onClick={() => handleSoftDeleteProduct(row.original.id)}
    //         className="bg-green-600 text-white px-2 py-3"
    //       >
    //         <FaTrash />
    //       </button>
    //     );
    //   },
    // },
  ];

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getProducts = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(`admin/product/all`);

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getProducts", pageIndex, cPageSize, cSortBy, desc, q], getProducts);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          <span className="btn-primary flex items-center mt-5 w-48">
            Selected Row : {selectedRows.length}
          </span>

        </div>

      </header>

      {component !== null && component}
    </div>
  );
};

export default AllProducts;
