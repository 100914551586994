import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useDispatch } from "react-redux";
import { FaEye, FaPen } from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import { ADD_SOIL_PIT_MODAL, EDIT_SOIL_PIT_MODAL } from "../../types/constants";
import {
  ERROR,
  SUCCESS
} from "../../types/constants";
import { addToast } from "../../redux/features/toastSlice";
import { AxiosError } from "axios";
import { FaTrash } from "react-icons/fa";
import { useQuery, useQueryClient } from "react-query";

interface UserObj {
  id: string;
  name: string;
  createdAt: string;
}

interface UserProps {
  otherUrl?: string;
}

const SoilPit = ({ otherUrl }: UserProps) => {
  const queryClient = useQueryClient();

  const ALL_USERS_COLUMNS = [
    {
      Header: "Sr. No",
      accessor: "serialNumber",
      Cell: ({ row }: any) => {
        return row.index + 1;
      },
    },
    {
      Header: "Soil Pit Id",
      accessor: "soil_pit_id",
      Cell: ({ row }: any) => row?.original?.soil_pit_id || "Not Available",
    },
    {
      Header: "Pit Name",
      accessor: "pit_name",
      Cell: ({ row }: any) => row?.original?.pit_name || "Not Available",
    },
    {
      Header: "Pit Length (cm)",
      accessor: "pit_length",
      Cell: ({ row }: any) => row?.original?.pit_length || " 0",
    },
    {
      Header: "Pit Width (cm)",
      accessor: "pit_width",
      Cell: ({ row }: any) => row?.original?.pit_width || " 0",
    },
    {
      Header: "Pit Height (cm)",
      accessor: "pit_height",
      Cell: ({ row }: any) => row?.original?.pit_height || " 0",
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: ({ row }: any) => row?.original?.address || "Not Available",
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ value }: any) => {
        return value ? new Date(value).toLocaleString() : "Invalid Date";
      },
    },
    {
      Header: "Pit Photo",
      accessor: "pit_photo",
      Cell: ({ value }: any) => {
        return value ? (
          <a
            href={value}
            className="text-green-400"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-green-600 text-white px-2 py-3">
              <FaEye />
            </button>
          </a>
        ) : (
          <p className="text-red-500">No image</p>
        );
      },
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_SOIL_PIT_MODAL,
                  modalTitle: "Edit Soil Pit",
                  modalProps: {
                    soilPitId: row?.original.soil_pit_id,
                    pitName: row?.original.pit_name,
                    pitLength: row?.original.pit_length,
                    pitWidth: row?.original.pit_width,
                    pitHeight: row?.original.pit_height,
                    address: row?.original.address,
                    pitPhoto: row?.original.pit_photo,
                    addressId: row?.original.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <FaPen />
          </button>
        );
      },
    },
    {
      Header: "Delete Soil Pit",
      accessor: "deleteMaterial",
      Cell: ({ value, row }: any) => {
        return (
          <button
            onClick={() => {
              const soil_pit_id = row?.original?.soil_pit_id;
              if (!soil_pit_id) {
                dispatch(
                  addToast({
                    kind: ERROR,
                    msg: "Error: Soil Pit ID is missing.",
                  })
                );
                return;
              }
              if (!window.confirm("Are you sure you want to delete this soil pit?")) {
                return;
              }
              axiosInstance.delete(`/soil_pit/${soil_pit_id}`)
                .then((res) => {

                  dispatch(
                    addToast({
                      kind: SUCCESS,
                      msg: "Soil Pit Deleted Successfully",
                    })
                  );
                  queryClient.invalidateQueries("getAllSoilPitDetails")
                }).catch((error: AxiosError) => {
                  console.log(error.response);
                  dispatch(
                    addToast({
                      kind: ERROR,
                      msg: `Error: ${error.response?.data?.msg || "Failed to delete Soil Pit"}`,
                    })
                  );
                  return;
                })
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            <FaTrash />
          </button>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  let component = null;

  const getAllSoilPitDetails = async ({ queryKey }: any) => {
    const { data } = await axiosInstance.get(otherUrl || `/soil_pit/all`);

    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(
    ["getAllSoilPitDetails", pageIndex, cPageSize, cSortBy, desc, q],
    getAllSoilPitDetails
  );

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance excludeColumnSearch={[]}
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1}
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => { }}
          />

          <span className="btn-primary flex items-center mt-5 w-48">
            Selected Row : {selectedRows.length}
          </span>

        </div>

        <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_SOIL_PIT_MODAL,
                  modalTitle: "Add Soil Pit",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div>
      </header>

      {component !== null && component}
    </div>
  );
};

export default SoilPit;
