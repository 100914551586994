import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { showModal } from "../../redux/features/modalSlice";
import { EDIT_BUYER_MODAL } from "../../types/constants";
import { FaEye, FaPen } from "react-icons/fa";
import { useParams } from "react-router-dom";
import SearchBox from "../../components/SearchBox/SearchBox";

interface Address {
    id: string;
    label: string;
    address: string;
    city: string;
    state: string;
    pincode: string;
    addressProofUrl: string;
    coordinates: string;
}

interface UserBuyerDetailsObj {
    id: string;
    name: string;
    phonenumber: string;
    email: string;
    role: string;
    fpo_id: string | null;
    internal_fpo_user: boolean;
    addresses: Address[];
    createdAt?: string;
    updatedAt?: string;
}

const UserBuyerDetails = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { id } = useParams();

    const formatDate = (dateString?: string) => {
        return dateString ? new Date(dateString).toLocaleString() : "Not Available";
    };

    const getUserDetails = async () => {
        try {
            const { data } = await axiosInstance.get(`/admin/user/details/${id}`);
            return data?.data ? [data.data] : [];
        } catch (error) {
            console.error("Error fetching user details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<UserBuyerDetailsObj[]>(
        ["getUserDetails", id],
        getUserDetails
    );

    return (
        <div className="p-6">
            {/* <h1 className="text-2xl font-bold mb-4">User Details</h1> */}
            {error && <p className="text-red-600">Error: {(error as Error).message}</p>}
            {isLoading ? (
                <p className="text-center">Loading...</p>
            ) : (
                <div className="bg-white shadow-md p-6 rounded-lg space-y-4 text-gray-800">
                    {data?.map((user: UserBuyerDetailsObj) => (
                        <div key={user?.id} className="border-b pb-4">
                            {/* Basic User Information */}
                            <div className="flex justify-between border-b pb-2">
                                <strong>Name:</strong>
                                <span>{user.name}</span>
                            </div>
                            <div className="flex justify-between border-b pb-2">
                                <strong>Phone Number:</strong>
                                <span>{user.phonenumber}</span>
                            </div>
                            <div className="flex justify-between border-b pb-2">
                                <strong>Email:</strong>
                                <span>{user.email || "Not Available"}</span>
                            </div>
                            <div className="flex justify-between border-b pb-2">
                                <strong>Role:</strong>
                                <span>{user.role}</span>
                            </div>
                            <div className="flex justify-between border-b pb-2">
                                <strong>Internal FPO User:</strong>
                                <span>{user.internal_fpo_user ? "Yes" : "No"}</span>
                            </div>
                            <div className="flex justify-between border-b pb-2">
                                <strong>Created At:</strong>
                                <span>{formatDate(user.createdAt)}</span>
                            </div>
                            <div className="flex justify-between border-b pb-2">
                                <strong>Updated At:</strong>
                                <span>{formatDate(user.updatedAt)}</span>
                            </div>

                            {/* Addresses Section */}
                            <div className="mt-4">
                                <h3 className="text-lg font-semibold mb-2">Addresses</h3>
                                {user.addresses.length > 0 ? (
                                    user.addresses.map((address) => (
                                        <div key={address.id} className="border p-4 rounded-lg mb-4">
                                            <div className="flex justify-between border-b pb-2">
                                                <strong>Label:</strong>
                                                <span>{address.label}</span>
                                            </div>
                                            <div className="flex justify-between border-b pb-2">
                                                <strong>Address:</strong>
                                                <span>{address.address}</span>
                                            </div>
                                            <div className="flex justify-between border-b pb-2">
                                                <strong>City:</strong>
                                                <span>{address.city}</span>
                                            </div>
                                            <div className="flex justify-between border-b pb-2">
                                                <strong>State:</strong>
                                                <span>{address.state}</span>
                                            </div>
                                            <div className="flex justify-between border-b pb-2">
                                                <strong>Pincode:</strong>
                                                <span>{address.pincode}</span>
                                            </div>
                                            <div className="flex justify-between border-b pb-2">
                                                <strong>Coordinates:</strong>
                                                <span>
                                                    {address.coordinates ? (
                                                        <a
                                                            href={`https://www.google.com/maps?q=${address.coordinates}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-600 underline"
                                                        >
                                                            View on Map
                                                        </a>
                                                    ) : (
                                                        "Not Available"
                                                    )}
                                                </span>
                                            </div>
                                            {address.addressProofUrl && (
                                                <div className="flex justify-between border-b pb-2">
                                                    <strong>Address Proof:</strong>
                                                    <a
                                                        href={address.addressProofUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-600 underline"
                                                    >
                                                        View Proof
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-600">No addresses available</p>
                                )}
                            </div>

                            {/* Action Buttons */}
                            <div className="flex space-x-3 mt-4">
                                <button
                                    onClick={() =>
                                        dispatch(
                                            showModal({
                                                modalType: EDIT_BUYER_MODAL,
                                                modalTitle: "Edit User Details",
                                                modalProps: {
                                                    user_id: user.id,
                                                    name: user.name,
                                                    role: user.role,
                                                    email: user.email,
                                                },
                                            })
                                        )
                                    }
                                    className="bg-blue-600 text-white p-2 rounded"
                                >
                                    <FaPen />
                                </button>
                                {user.addresses.some(addr => addr.addressProofUrl) && (
                                    <button className="bg-green-600 text-white p-2 rounded">
                                        <FaEye />
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default UserBuyerDetails;