import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { AZURE_STORAGE_DIRECTORY, AZURE_STORAGE_SUBDIRECTORY, ERROR, SUCCESS, LENGTH_UNIT } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import ImagePreview from "../ModalComponents/ImagePreview";
import { uploadImage } from "../../http/uploadImage";

interface BiomassConfigObj {
  biomassName: string;
  avgBiomassDia: number;
  avgBiomassLen: number;
  biomassPhoto: string;
  cottonStock: number;
  riceStraw: number;
  test: string;
}

type EditBiomassConfigModalProps = {
  biomassConfigId: string;
  biomassName: string;
  avgBiomassDia: number;
  avgBiomassLen: number;
  biomassPhoto: string;
  cottonStock: number;
  riceStraw: number;
  test: string;
  addressId: string;
};

const EditBiomassConfigModal: React.FC<EditBiomassConfigModalProps> = ({
  biomassConfigId,
  biomassName,
  avgBiomassDia,
  avgBiomassLen,
  biomassPhoto,
  cottonStock,
  riceStraw,
  test,
  addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImg, setNewImg] = useState<File | null>(null);
  const [diaUnit, setDiaUnit] = useState("cm");
  const [lenUnit, setLenUnit] = useState("cm");

  const initialValues: BiomassConfigObj = {
    biomassName,
    avgBiomassDia,
    avgBiomassLen,
    biomassPhoto,
    cottonStock,
    riceStraw,
    test,
  };

  const validationSchema = Yup.object({
    biomassName: Yup.string().trim().required("Biomass Name is Required"),
    avgBiomassDia: Yup.number().min(0.002, "Diameter must be at least 0.002").required("Average Biomass Diameter is Required"),
    avgBiomassLen: Yup.number().min(0.002, "Length must be at least 0.002").required("Average Biomass Length is Required"),
    biomassPhoto: Yup.string().required("Biomass Image is Required"),
    cottonStock: Yup.number().required("Cotton Stock is Required"),
    riceStraw: Yup.number().required("Rice Straw is Required"),
    test: Yup.string().trim(),
  });

  const getConversionFactor = (from: string, to: string) => {
    const factorToCm: { [key: string]: number } = { mm: 0.1, cm: 1, m: 100 };
    const factorFromCm: { [key: string]: number } = { mm: 10, cm: 1, m: 0.01 };
    return factorToCm[from] * factorFromCm[to];
  };

  const handleSubmit = useCallback(
    async (biomass_config: BiomassConfigObj) => {
      setIsSubmitting(true);
      try {
        let finalPhotoUrl = biomassPhoto;
        if (newImg) {
          const imgRes = await uploadImage(newImg, AZURE_STORAGE_DIRECTORY.BIOMASS_COMPOSITION, AZURE_STORAGE_SUBDIRECTORY.PROFILE);
          if (imgRes.data.publicUrl) {
            finalPhotoUrl = imgRes.data.publicUrl;
          } else {
            throw new Error("Image upload failed");
          }
        }

        await axiosInstance.patch(
          `/biomass_composition/${biomassConfigId}`,
          {
            biomass_name: biomass_config.biomassName,
            average_biomass_diameter: biomass_config.avgBiomassDia,
            average_biomass_length: biomass_config.avgBiomassLen,
            biomass_photo: finalPhotoUrl,
            biomass_blend: {
              cotton_stock: biomass_config.cottonStock,
              rice_straw: biomass_config.riceStraw,
              test: biomass_config.test,
            },
            addressId,
          },
          { headers: { "Content-Type": "application/json" } }
        )

        queryClient.invalidateQueries(["getAllBiomassComposition"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Biomass Config Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        const errorMsg = error.response?.data?.msg || "Oops, something went wrong";
        dispatch(
          addToast({
            kind: ERROR,
            msg: errorMsg
          }
          ));
      }
      setIsSubmitting(false);
    },
    [biomassConfigId, addressId, queryClient, dispatch, newImg, biomassPhoto]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, setFieldValue }) => (
        <Form
          className="px-6 py-4 mt-2"
          // style={{
          //   minWidth: "360px",
          //   maxWidth: "760px",
          // }}
          style={{ minWidth: "40vw" }}
        >
          <Input
            label="Biomass Name"
            id="biomassName"
            name="biomassName"
            type="text"
          />

          <h2 className="text-xl font-semibold">Biomass Blend Details</h2>

          <Input
            label="Cotton Stock"
            id="cottonStock"
            name="cottonStock"
            type="number"
          />

          <Input
            label="Rice Straw"
            id="riceStraw"
            name="riceStraw"
            type="number"
          />

          <Input
            label="Test"
            id="test"
            name="test"
            type="text"
          />

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Average Biomass Diameter (${diaUnit})`}
                id="avgBiomassDia"
                name="avgBiomassDia"
                type="number"
              />
            </div>
            <div>
              <select
                value={diaUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  setFieldValue("avgBiomassDia", values.avgBiomassDia * getConversionFactor(diaUnit, newUnit));
                  setDiaUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-x-6">
            <div className="flex-1">
              <Input
                label={`Average Biomass Length (${lenUnit})`}
                id="avgBiomassLen"
                name="avgBiomassLen"
                type="number" />
            </div>
            <div>
              <select
                value={lenUnit}
                className="p-2 border rounded"
                onChange={(e) => {
                  const newUnit = e.target.value;
                  setFieldValue("avgBiomassLen", values.avgBiomassLen * getConversionFactor(lenUnit, newUnit));
                  setLenUnit(newUnit);
                }}
              >
                {LENGTH_UNIT.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <ImagePreview
            imageUrl={biomassPhoto}
            altText="Biomass Photo Preview"
          />

          <Input
            label="Upload Biomass Photo"
            id="new_biomass_photo"
            name="new_biomass_photo"
            type="file"
            accept=".jpg,.png,.jpeg,.webp"
          />

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              // classes="text-sm"
              // checkDirty={false}
              containerClasses="h-full"
              isSubmittingClasses="lg:mt-6"
              classes="text-sm lg:p-4 lg:rounded-lg"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditBiomassConfigModal;
