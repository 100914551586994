import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInstance";
import { showModal } from "../../redux/features/modalSlice";
import { EDIT_PRODUCT_MODAL } from "../../types/constants";
import { FaEye, FaPen, FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";

interface AllProductsDetailsObj {
    id: string;
    name: string;
    category?: string;
    productDescription?: string;
    actualPrice?: number;
    discountedPrice?: number;
    stockInKG?: number;
    isActive?: boolean;
    is_biomass?: boolean;
    estimatedDeliveryTimeInDays?: number;
    packageSizeGrams?: number | null;
    packagingType?: string;
    sellerId?: string;
    productCategoryId?: string;
    city?: string;
    state?: string;
    pincode?: string;
    address?: string;
    coordinates?: { x?: number; y?: number };
    geofence_coordinates?: string | null;
    verified?: boolean;
    createdAt: string;
    updatedAt: string;
    addressProofUrl?: string;
}

const AllProductsDetails = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { id } = useParams();

    const handleApproveProduct = (productId?: string) => {
        if (!productId) return;
        axiosInstance.get(`/admin/products/${productId}/approve`).then(() => {
            toast.success("Product Approved Successfully!");
            queryClient.invalidateQueries(["productDetails", id]);
        });
    };

    const handleSoftDeleteProduct = (productId?: string) => {
        if (!productId) return;
        axiosInstance.delete(`/products/${productId}`).then(() => {
            toast.success("Product Deleted Successfully!");
            queryClient.invalidateQueries(["productDetails", id]);
        });
    };

    const formatDate = (dateString?: string) => {
        return dateString ? new Date(dateString).toLocaleString() : "Not Available";
    };

    const getProducts = async () => {
        try {
            const response = await axiosInstance.get(`admin/product/details/${id}`);
            return response.data.data;
        } catch (error) {
            console.error("Error fetching product details:", error);
            throw error;
        }
    };

    const { data, error, isLoading } = useQuery<AllProductsDetailsObj>(
        ["productDetails", id],  // Changed query key to be more specific
        getProducts
    );

    if (isLoading) return <p className="text-center">Loading...</p>;
    if (error) return <p className="text-red-600 text-center">Error: {(error as Error).message}</p>;

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Product Details</h1>
            {data ? (
                <div className="bg-white shadow-md p-6 rounded-lg space-y-4 text-gray-800">
                    <div key={data.id} className="border-b pb-4">
                        {Object.entries(data).map(([key, value]) => (
                            value !== undefined &&
                            !/^[a-f0-9-]{36}$/.test(value as string) && (
                                <div className="flex justify-between border-b pb-2" key={key}>
                                    <strong className="capitalize">
                                        {key.replace(/_/g, ' ')
                                            .replace(/([a-z])([A-Z])/g, '$1 $2')}:
                                    </strong>
                                    <span className="max-w-[60%] text-right">
                                        {key.includes("createdAt") || key.includes("updatedAt")
                                            ? formatDate(value as string)
                                            : key === "coordinates" && typeof value === "object"
                                                ? <a
                                                    href={`https://www.google.com/maps?q=${(value as { x?: number; y?: number })?.x},${(value as { x?: number; y?: number })?.y}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 underline"
                                                >
                                                    View on Map
                                                </a>
                                                : typeof value === "string" && value.startsWith("http")
                                                    ? <a
                                                        href={value}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="text-blue-600 underline"
                                                    >
                                                        View Image
                                                    </a>
                                                    : typeof value === "object" || typeof value === "boolean" || typeof value === "number"
                                                        ? JSON.stringify(value)
                                                        : (value as string)?.toString() || "Not Available"}
                                    </span>
                                </div>
                            )
                        ))}
                        <div className="mt-4 flex flex-col gap-4">
                            {data.verified ? (
                                <p className="text-green-600 font-bold">Verified</p>
                            ) : (
                                <button
                                    onClick={() => handleApproveProduct(data.id)}
                                    className="bg-green-600 text-white px-4 py-2 rounded w-fit"
                                >
                                    Approve Product
                                </button>
                            )}
                            <div className="flex space-x-3">
                                <button
                                    onClick={() =>
                                        dispatch(
                                            showModal({
                                                modalType: EDIT_PRODUCT_MODAL,
                                                modalTitle: "Edit Product",
                                                modalProps: data,
                                            })
                                        )
                                    }
                                    className="bg-blue-600 text-white p-2 rounded"
                                >
                                    <FaPen />
                                </button>
                                <button
                                    onClick={() => {
                                        if (!window.confirm("Are you sure you want to delete this product?")) {
                                            return;
                                        }
                                        handleSoftDeleteProduct(data.id);
                                    }}
                                    className="bg-red-600 text-white p-2 rounded"
                                >
                                    <FaTrash />
                                </button>

                                {data.addressProofUrl && (
                                    <a href={data.addressProofUrl} target="_blank" rel="noreferrer">
                                        <button className="bg-green-600 text-white p-2 rounded">
                                            <FaEye />
                                        </button>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p className="text-center">No product data found</p>
            )}
        </div>
    );
};

export default AllProductsDetails;