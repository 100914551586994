import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface CropObj {
  name: string;
  type: string;
}

const AddCropModal = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: CropObj = {
    name: "",
    type: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().trim().required("Crop Name is Required"),
    type: Yup.string().trim().required("Crop Type is Required"),
  });

  const handleSubmit = useCallback((crop: CropObj) => {
    setIsSubmitting(true);

    axiosInstance
      .post(
        `/farming/crops`,
        {
          name: crop.name,
          type: crop.type,
        },
        {
          headers: {
            ContentType: "application/json",
          },
        }
      )
      .then((response) => {
        const { msg } = response.data;

        setIsSubmitting(false);

        queryClient.invalidateQueries(["getCrops"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Crop Added Successfully!",
          })
        );

        dispatch(hideModal());
      })
      .catch((error: AxiosError) => {
        setIsSubmitting(false);

        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            // bad request or invalid format or unauthorized
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        // style={{
        //   minWidth: "360px",
        //   maxWidth: "760px",
        // }}
        style={{ minWidth: "40vw" }}
      >
        <div className="flex item-center gap-x-6">
          <Input label="Crop Name" id="name" name="name" type="text" />
        </div>

        <div className="flex item-center gap-x-6">
          <Input label="Crop Type" id="type" name="type" type="text" />
        </div>


        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            // classes="text-sm"
            containerClasses="h-full"
            isSubmittingClasses="lg:mt-6"
            classes="text-sm lg:p-4 lg:rounded-lg"
          />
        </div>

      </Form>
    </Formik>
  );
};

export default AddCropModal;
